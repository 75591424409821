// MODAL PRICE DEMO
import { getExampleNumber, AsYouType, parsePhoneNumber } from 'libphonenumber-js'
import libPhoneNumberExamples from 'libphonenumber-js/examples.mobile.json'

window.htr_showModalPriceDemo = function (modalContainerId, productId, companyId, titleString = null, listOfPreselectedTopProducts = null, preSelectedPropertySize = null) {
  const htrLanguage = window.i18n
  let modalContainerElement = $('#' + modalContainerId)
  let datePreferedTime = document.getElementById(modalContainerId).getAttribute('datePreferedTime')
  window.htr_showModalPriceDemo.autodecline = false
  window.htr_showModalPriceDemo.prefered_time = null
  window.htr_showModalPriceDemo.prefered_date = null
  if (datePreferedTime) {
    const datePreferedTimeObj = JSON.parse(datePreferedTime)
    window.htr_showModalPriceDemo.prefered_time = datePreferedTimeObj.prefered_time
    window.htr_showModalPriceDemo.prefered_date = datePreferedTimeObj.prefered_date
  }
  let messages = {
    'request_demo': (htrLanguage.company.demo || 'demo').toLowerCase(),
    'request_quote': (htrLanguage.company.price_quote || 'price_quote').toLowerCase()
  }
  let stringMessages
  let dialCodes = {
    'US':{'name':'United States','dial_code':'+1','code':'US'},
    'AF':{'name':'Afghanistan','dial_code':'+93','code':'AF'},
    'AX':{'name':'Åland Islands','dial_code':'+358','code':'AX'},
    'AL':{'name':'Albania','dial_code':'+355','code':'AL'},
    'DZ':{'name':'Algeria','dial_code':'+213','code':'DZ'},
    'AS':{'name':'American Samoa','dial_code':'+1684','code':'AS'},
    'AD':{'name':'Andorra','dial_code':'+376','code':'AD'},
    'AO':{'name':'Angola','dial_code':'+244','code':'AO'},
    'AI':{'name':'Anguilla','dial_code':'+1264','code':'AI'},
    'AQ':{'name':'Antarctica','dial_code':'+672','code':'AQ'},
    'AG':{'name':'Antigua and Barbuda','dial_code':'+1268','code':'AG'},
    'AR':{'name':'Argentina','dial_code':'+54','code':'AR'},
    'AM':{'name':'Armenia','dial_code':'+374','code':'AM'},
    'AW':{'name':'Aruba','dial_code':'+297','code':'AW'},
    'AU':{'name':'Australia','dial_code':'+61','code':'AU'},
    'AT':{'name':'Austria','dial_code':'+43','code':'AT'},
    'AZ':{'name':'Azerbaijan','dial_code':'+994','code':'AZ'},
    'BS':{'name':'Bahamas','dial_code':'+1242','code':'BS'},
    'BH':{'name':'Bahrain','dial_code':'+973','code':'BH'},
    'BD':{'name':'Bangladesh','dial_code':'+880','code':'BD'},
    'BB':{'name':'Barbados','dial_code':'+1246','code':'BB'},
    'BY':{'name':'Belarus','dial_code':'+375','code':'BY'},
    'BE':{'name':'Belgium','dial_code':'+32','code':'BE'},
    'BZ':{'name':'Belize','dial_code':'+501','code':'BZ'},
    'BJ':{'name':'Benin','dial_code':'+229','code':'BJ'},
    'BM':{'name':'Bermuda','dial_code':'+1441','code':'BM'},
    'BT':{'name':'Bhutan','dial_code':'+975','code':'BT'},
    'BO':{'name':'Bolivia, Plurinational State of bolivia','dial_code':'+591','code':'BO'},
    'BA':{'name':'Bosnia and Herzegovina','dial_code':'+387','code':'BA'},
    'BW':{'name':'Botswana','dial_code':'+267','code':'BW'},
    'BV':{'name':'Bouvet Island','dial_code':'+47','code':'BV'},
    'BR':{'name':'Brazil','dial_code':'+55','code':'BR'},
    'IO':{'name':'British Indian Ocean Territory','dial_code':'+246','code':'IO'},
    'BN':{'name':'Brunei Darussalam','dial_code':'+673','code':'BN'},
    'BG':{'name':'Bulgaria','dial_code':'+359','code':'BG'},
    'BF':{'name':'Burkina Faso','dial_code':'+226','code':'BF'},
    'BI':{'name':'Burundi','dial_code':'+257','code':'BI'},
    'KH':{'name':'Cambodia','dial_code':'+855','code':'KH'},
    'CM':{'name':'Cameroon','dial_code':'+237','code':'CM'},
    'CA':{'name':'Canada','dial_code':'+1','code':'CA'},
    'CV':{'name':'Cape Verde','dial_code':'+238','code':'CV'},
    'KY':{'name':'Cayman Islands','dial_code':'+ 345','code':'KY'},
    'CF':{'name':'Central African Republic','dial_code':'+236','code':'CF'},
    'TD':{'name':'Chad','dial_code':'+235','code':'TD'},
    'CL':{'name':'Chile','dial_code':'+56','code':'CL'},
    'CN':{'name':'China','dial_code':'+86','code':'CN'},
    'CX':{'name':'Christmas Island','dial_code':'+61','code':'CX'},
    'CC':{'name':'Cocos (Keeling) Islands','dial_code':'+61','code':'CC'},
    'CO':{'name':'Colombia','dial_code':'+57','code':'CO'},
    'KM':{'name':'Comoros','dial_code':'+269','code':'KM'},
    'CG':{'name':'Congo','dial_code':'+242','code':'CG'},
    'CD':{'name':'Congo, The Democratic Republic of the Congo','dial_code':'+243','code':'CD'},
    'CK':{'name':'Cook Islands','dial_code':'+682','code':'CK'},
    'CR':{'name':'Costa Rica','dial_code':'+506','code':'CR'},
    'CI':{'name':"Cote d'Ivoire",'dial_code':'+225','code':'CI'},
    'HR':{'name':'Croatia','dial_code':'+385','code':'HR'},
    'CU':{'name':'Cuba','dial_code':'+53','code':'CU'},
    'CY':{'name':'Cyprus','dial_code':'+357','code':'CY'},
    'CZ':{'name':'Czech Republic','dial_code':'+420','code':'CZ'},
    'DK':{'name':'Denmark','dial_code':'+45','code':'DK'},
    'DJ':{'name':'Djibouti','dial_code':'+253','code':'DJ'},
    'DM':{'name':'Dominica','dial_code':'+1767','code':'DM'},
    'DO':{'name':'Dominican Republic','dial_code':'+1849','code':'DO'},
    'EC':{'name':'Ecuador','dial_code':'+593','code':'EC'},
    'EG':{'name':'Egypt','dial_code':'+20','code':'EG'},
    'SV':{'name':'El Salvador','dial_code':'+503','code':'SV'},
    'GQ':{'name':'Equatorial Guinea','dial_code':'+240','code':'GQ'},
    'ER':{'name':'Eritrea','dial_code':'+291','code':'ER'},
    'EE':{'name':'Estonia','dial_code':'+372','code':'EE'},
    'ET':{'name':'Ethiopia','dial_code':'+251','code':'ET'},
    'FK':{'name':'Falkland Islands (Malvinas)','dial_code':'+500','code':'FK'},
    'FO':{'name':'Faroe Islands','dial_code':'+298','code':'FO'},
    'FJ':{'name':'Fiji','dial_code':'+679','code':'FJ'},
    'FI':{'name':'Finland','dial_code':'+358','code':'FI'},
    'FR':{'name':'France','dial_code':'+33','code':'FR'},
    'GF':{'name':'French Guiana','dial_code':'+594','code':'GF'},
    'PF':{'name':'French Polynesia','dial_code':'+689','code':'PF'},
    'TF':{'name':'French Southern Territories','dial_code':'+262','code':'TF'},
    'GA':{'name':'Gabon','dial_code':'+241','code':'GA'},
    'GM':{'name':'Gambia','dial_code':'+220','code':'GM'},
    'GE':{'name':'Georgia','dial_code':'+995','code':'GE'},
    'DE':{'name':'Germany','dial_code':'+49','code':'DE'},
    'GH':{'name':'Ghana','dial_code':'+233','code':'GH'},
    'GI':{'name':'Gibraltar','dial_code':'+350','code':'GI'},
    'GR':{'name':'Greece','dial_code':'+30','code':'GR'},
    'GL':{'name':'Greenland','dial_code':'+299','code':'GL'},
    'GD':{'name':'Grenada','dial_code':'+1473','code':'GD'},
    'GP':{'name':'Guadeloupe','dial_code':'+590','code':'GP'},
    'GU':{'name':'Guam','dial_code':'+1671','code':'GU'},
    'GT':{'name':'Guatemala','dial_code':'+502','code':'GT'},
    'GG':{'name':'Guernsey','dial_code':'+44','code':'GG'},
    'GN':{'name':'Guinea','dial_code':'+224','code':'GN'},
    'GW':{'name':'Guinea-Bissau','dial_code':'+245','code':'GW'},
    'GY':{'name':'Guyana','dial_code':'+592','code':'GY'},
    'HT':{'name':'Haiti','dial_code':'+509','code':'HT'},
    'HM':{'name':'Heard Island and Mcdonald Islands','dial_code':'+0','code':'HM'},
    'VA':{'name':'Holy See (Vatican City State)','dial_code':'+379','code':'VA'},
    'HN':{'name':'Honduras','dial_code':'+504','code':'HN'},
    'HK':{'name':'Hong Kong','dial_code':'+852','code':'HK'},
    'HU':{'name':'Hungary','dial_code':'+36','code':'HU'},
    'IS':{'name':'Iceland','dial_code':'+354','code':'IS'},
    'IN':{'name':'India','dial_code':'+91','code':'IN'},
    'ID':{'name':'Indonesia','dial_code':'+62','code':'ID'},
    'IR':{'name':'Iran, Islamic Republic of Persian Gulf','dial_code':'+98','code':'IR'},
    'IQ':{'name':'Iraq','dial_code':'+964','code':'IQ'},
    'IE':{'name':'Ireland','dial_code':'+353','code':'IE'},
    'IM':{'name':'Isle of Man','dial_code':'+44','code':'IM'},
    'IL':{'name':'Israel','dial_code':'+972','code':'IL'},
    'IT':{'name':'Italy','dial_code':'+39','code':'IT'},
    'JM':{'name':'Jamaica','dial_code':'+1876','code':'JM'},
    'JP':{'name':'Japan','dial_code':'+81','code':'JP'},
    'JE':{'name':'Jersey','dial_code':'+44','code':'JE'},
    'JO':{'name':'Jordan','dial_code':'+962','code':'JO'},
    'KZ':{'name':'Kazakhstan','dial_code':'+7','code':'KZ'},
    'KE':{'name':'Kenya','dial_code':'+254','code':'KE'},
    'KI':{'name':'Kiribati','dial_code':'+686','code':'KI'},
    'KP':{'name':"Korea, Democratic People's Republic of Korea",'dial_code':'+850','code':'KP'},
    'KR':{'name':'Korea, Republic of South Korea','dial_code':'+82','code':'KR'},
    'XK':{'name':'Kosovo','dial_code':'+383','code':'XK'},
    'KW':{'name':'Kuwait','dial_code':'+965','code':'KW'},
    'KG':{'name':'Kyrgyzstan','dial_code':'+996','code':'KG'},
    'LA':{'name':'Laos','dial_code':'+856','code':'LA'},
    'LV':{'name':'Latvia','dial_code':'+371','code':'LV'},
    'LB':{'name':'Lebanon','dial_code':'+961','code':'LB'},
    'LS':{'name':'Lesotho','dial_code':'+266','code':'LS'},
    'LR':{'name':'Liberia','dial_code':'+231','code':'LR'},
    'LY':{'name':'Libyan Arab Jamahiriya','dial_code':'+218','code':'LY'},
    'LI':{'name':'Liechtenstein','dial_code':'+423','code':'LI'},
    'LT':{'name':'Lithuania','dial_code':'+370','code':'LT'},
    'LU':{'name':'Luxembourg','dial_code':'+352','code':'LU'},
    'MO':{'name':'Macao','dial_code':'+853','code':'MO'},
    'MK':{'name':'Macedonia','dial_code':'+389','code':'MK'},
    'MG':{'name':'Madagascar','dial_code':'+261','code':'MG'},
    'MW':{'name':'Malawi','dial_code':'+265','code':'MW'},
    'MY':{'name':'Malaysia','dial_code':'+60','code':'MY'},
    'MV':{'name':'Maldives','dial_code':'+960','code':'MV'},
    'ML':{'name':'Mali','dial_code':'+223','code':'ML'},
    'MT':{'name':'Malta','dial_code':'+356','code':'MT'},
    'MH':{'name':'Marshall Islands','dial_code':'+692','code':'MH'},
    'MQ':{'name':'Martinique','dial_code':'+596','code':'MQ'},
    'MR':{'name':'Mauritania','dial_code':'+222','code':'MR'},
    'MU':{'name':'Mauritius','dial_code':'+230','code':'MU'},
    'YT':{'name':'Mayotte','dial_code':'+262','code':'YT'},
    'MX':{'name':'Mexico','dial_code':'+52','code':'MX'},
    'FM':{'name':'Micronesia, Federated States of Micronesia','dial_code':'+691','code':'FM'},
    'MD':{'name':'Moldova','dial_code':'+373','code':'MD'},
    'MC':{'name':'Monaco','dial_code':'+377','code':'MC'},
    'MN':{'name':'Mongolia','dial_code':'+976','code':'MN'},
    'ME':{'name':'Montenegro','dial_code':'+382','code':'ME'},
    'MS':{'name':'Montserrat','dial_code':'+1664','code':'MS'},
    'MA':{'name':'Morocco','dial_code':'+212','code':'MA'},
    'MZ':{'name':'Mozambique','dial_code':'+258','code':'MZ'},
    'MM':{'name':'Myanmar','dial_code':'+95','code':'MM'},
    'NA':{'name':'Namibia','dial_code':'+264','code':'NA'},
    'NR':{'name':'Nauru','dial_code':'+674','code':'NR'},
    'NP':{'name':'Nepal','dial_code':'+977','code':'NP'},
    'NL':{'name':'Netherlands','dial_code':'+31','code':'NL'},
    'AN':{'name':'Netherlands Antilles','dial_code':'+599','code':'AN'},
    'NC':{'name':'New Caledonia','dial_code':'+687','code':'NC'},
    'NZ':{'name':'New Zealand','dial_code':'+64','code':'NZ'},
    'NI':{'name':'Nicaragua','dial_code':'+505','code':'NI'},
    'NE':{'name':'Niger','dial_code':'+227','code':'NE'},
    'NG':{'name':'Nigeria','dial_code':'+234','code':'NG'},
    'NU':{'name':'Niue','dial_code':'+683','code':'NU'},
    'NF':{'name':'Norfolk Island','dial_code':'+672','code':'NF'},
    'MP':{'name':'Northern Mariana Islands','dial_code':'+1670','code':'MP'},
    'NO':{'name':'Norway','dial_code':'+47','code':'NO'},
    'OM':{'name':'Oman','dial_code':'+968','code':'OM'},
    'PK':{'name':'Pakistan','dial_code':'+92','code':'PK'},
    'PW':{'name':'Palau','dial_code':'+680','code':'PW'},
    'PS':{'name':'Palestinian Territory, Occupied','dial_code':'+970','code':'PS'},
    'PA':{'name':'Panama','dial_code':'+507','code':'PA'},
    'PG':{'name':'Papua New Guinea','dial_code':'+675','code':'PG'},
    'PY':{'name':'Paraguay','dial_code':'+595','code':'PY'},
    'PE':{'name':'Peru','dial_code':'+51','code':'PE'},
    'PH':{'name':'Philippines','dial_code':'+63','code':'PH'},
    'PN':{'name':'Pitcairn','dial_code':'+64','code':'PN'},
    'PL':{'name':'Poland','dial_code':'+48','code':'PL'},
    'PT':{'name':'Portugal','dial_code':'+351','code':'PT'},
    'PR':{'name':'Puerto Rico','dial_code':'+1939','code':'PR'},
    'QA':{'name':'Qatar','dial_code':'+974','code':'QA'},
    'RO':{'name':'Romania','dial_code':'+40','code':'RO'},
    'RU':{'name':'Russia','dial_code':'+7','code':'RU'},
    'RW':{'name':'Rwanda','dial_code':'+250','code':'RW'},
    'RE':{'name':'Reunion','dial_code':'+262','code':'RE'},
    'BL':{'name':'Saint Barthelemy','dial_code':'+590','code':'BL'},
    'SH':{'name':'Saint Helena, Ascension and Tristan Da Cunha','dial_code':'+290','code':'SH'},
    'KN':{'name':'Saint Kitts and Nevis','dial_code':'+1869','code':'KN'},
    'LC':{'name':'Saint Lucia','dial_code':'+1758','code':'LC'},
    'MF':{'name':'Saint Martin','dial_code':'+590','code':'MF'},
    'PM':{'name':'Saint Pierre and Miquelon','dial_code':'+508','code':'PM'},
    'VC':{'name':'Saint Vincent and the Grenadines','dial_code':'+1784','code':'VC'},
    'WS':{'name':'Samoa','dial_code':'+685','code':'WS'},
    'SM':{'name':'San Marino','dial_code':'+378','code':'SM'},
    'ST':{'name':'Sao Tome and Principe','dial_code':'+239','code':'ST'},
    'SA':{'name':'Saudi Arabia','dial_code':'+966','code':'SA'},
    'SN':{'name':'Senegal','dial_code':'+221','code':'SN'},
    'RS':{'name':'Serbia','dial_code':'+381','code':'RS'},
    'SC':{'name':'Seychelles','dial_code':'+248','code':'SC'},
    'SL':{'name':'Sierra Leone','dial_code':'+232','code':'SL'},
    'SG':{'name':'Singapore','dial_code':'+65','code':'SG'},
    'SK':{'name':'Slovakia','dial_code':'+421','code':'SK'},
    'SI':{'name':'Slovenia','dial_code':'+386','code':'SI'},
    'SB':{'name':'Solomon Islands','dial_code':'+677','code':'SB'},
    'SO':{'name':'Somalia','dial_code':'+252','code':'SO'},
    'ZA':{'name':'South Africa','dial_code':'+27','code':'ZA'},
    'SS':{'name':'South Sudan','dial_code':'+211','code':'SS'},
    'GS':{'name':'South Georgia and the South Sandwich Islands','dial_code':'+500','code':'GS'},
    'ES':{'name':'Spain','dial_code':'+34','code':'ES'},
    'LK':{'name':'Sri Lanka','dial_code':'+94','code':'LK'},
    'SD':{'name':'Sudan','dial_code':'+249','code':'SD'},
    'SR':{'name':'Suriname','dial_code':'+597','code':'SR'},
    'SJ':{'name':'Svalbard and Jan Mayen','dial_code':'+47','code':'SJ'},
    'SZ':{'name':'Swaziland','dial_code':'+268','code':'SZ'},
    'SE':{'name':'Sweden','dial_code':'+46','code':'SE'},
    'CH':{'name':'Switzerland','dial_code':'+41','code':'CH'},
    'SY':{'name':'Syrian Arab Republic','dial_code':'+963','code':'SY'},
    'TW':{'name':'Taiwan','dial_code':'+886','code':'TW'},
    'TJ':{'name':'Tajikistan','dial_code':'+992','code':'TJ'},
    'TZ':{'name':'Tanzania, United Republic of Tanzania','dial_code':'+255','code':'TZ'},
    'TH':{'name':'Thailand','dial_code':'+66','code':'TH'},
    'TL':{'name':'Timor-Leste','dial_code':'+670','code':'TL'},
    'TG':{'name':'Togo','dial_code':'+228','code':'TG'},
    'TK':{'name':'Tokelau','dial_code':'+690','code':'TK'},
    'TO':{'name':'Tonga','dial_code':'+676','code':'TO'},
    'TT':{'name':'Trinidad and Tobago','dial_code':'+1868','code':'TT'},
    'TN':{'name':'Tunisia','dial_code':'+216','code':'TN'},
    'TR':{'name':'Turkey','dial_code':'+90','code':'TR'},
    'TM':{'name':'Turkmenistan','dial_code':'+993','code':'TM'},
    'TC':{'name':'Turks and Caicos Islands','dial_code':'+1649','code':'TC'},
    'TV':{'name':'Tuvalu','dial_code':'+688','code':'TV'},
    'UG':{'name':'Uganda','dial_code':'+256','code':'UG'},
    'UA':{'name':'Ukraine','dial_code':'+380','code':'UA'},
    'AE':{'name':'United Arab Emirates','dial_code':'+971','code':'AE'},
    'GB':{'name':'United Kingdom','dial_code':'+44','code':'GB'},
    'UY':{'name':'Uruguay','dial_code':'+598','code':'UY'},
    'UZ':{'name':'Uzbekistan','dial_code':'+998','code':'UZ'},
    'VU':{'name':'Vanuatu','dial_code':'+678','code':'VU'},
    'VE':{'name':'Venezuela, Bolivarian Republic of Venezuela','dial_code':'+58','code':'VE'},
    'VN':{'name':'Vietnam','dial_code':'+84','code':'VN'},
    'VG':{'name':'Virgin Islands, British','dial_code':'+1284','code':'VG'},
    'VI':{'name':'Virgin Islands, U.S.','dial_code':'+1340','code':'VI'},
    'WF':{'name':'Wallis and Futuna','dial_code':'+681','code':'WF'},
    'YE':{'name':'Yemen','dial_code':'+967','code':'YE'},
    'ZM':{'name':'Zambia','dial_code':'+260','code':'ZM'},
    'ZW':{'name':'Zimbabwe','dial_code':'+263','code':'ZW'}
  };

  if(titleString && typeof titleString === 'string'){
    stringMessages = messages.hasOwnProperty(titleString) ? messages[titleString] : ''
  }

  if(!modalContainerElement.length || !productId || !companyId) {
    return 0
  }

  if (window.Laravel.isVendor === true && !window.Laravel.user.is_admin) {
    notForVendor(modalContainerId);
    return 0;
  }

  window.htr_showModalPriceDemo.requestType = titleString

  window.htr_modal_container_id = modalContainerId
  let htmlContent
  htmlContent = `
  <div class="modal-price-demo-wrapper">
    <div class="modal-price-demo-container">
      <div id="modal-price-demo-aux-modal-container-01">
      </div>
      <div id="modal-price-demo-container-01" class="modal-price-demo-container-info">
        <span id="modal-price-demo-spinner-01" class="lds-spinner">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div id="modal-price-demo-container-info-header-00" class="modal-price-demo-container-info-header">
          <div class="modal-price-demo-container-close-button">
            <button>
              <i class="icon-cross"></i>
            </button>
          </div>
        </div>
        <div id="modal-price-demo-container-info-content-00" class="modal-price-demo-container-info-content">
        </div>
        <span id="modal-price-demo-spinner-02" class="lds-spinner" style="display: none">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div id="modal-price-demo-container-info-footer-00" class="modal-price-demo-container-info-footer">
        </div>
      </div>
      <div class="modal-price-demo-container-logos">
        <div class="modal-price-demo-container-logos-title">${ htrLanguage.company.trusted_by_the_world || 'trusted_by_the_world' }</div>
        <div class="modal-price-demo-container-logos-images">
            <img src="/images/logo-fairmont-white.svg" width="70" alt="Fairmont" class="lazyload" >
            <img src="/images/logo-omni-white.svg" width="70" alt="Omnt" class="lazyload" >
            <img src="/images/logo-accor-hotels-white.svg" width="79" alt="Accor" class="lazyload" >
            <img src="/images/logo-denihan-hospitality-group-white.svg" width="71" alt="Denihan" class="lazyload" >
            <img src="/images/logo-kimpton-white.svg" width="64" alt="Kimpton" class="lazyload" >
            <img src="/images/logo-marriott-white.svg" width="57" alt="Marriott" class="lazyload" >
        </div>
      </div>
    </div>
  </div>`

  let productUrl = `/api/products/${productId}`
  let companyUrl = `/api/companies/${companyId}`
  let productPmsUrl = `/api/products/names/all/pms`


  modalContainerElement.append(htmlContent)
  modalContainerElement.css('display', 'inherit')

  if(window.matchMedia('(max-width: 767px)').matches) {
    let innerHeight = window.innerHeight
    $('#modal-price-demo-container-info-content-00').height(innerHeight)
  }

  if(window.matchMedia('(min-width: 768px)').matches) {
    $('.modal-price-demo-container').animate({top: 40, opacity: 1}, 'fast')
  } else {
    $('.modal-price-demo-container').animate({opacity: 1}, 'fast')
  }

  $('.modal-price-demo-container-close-button').on('click', (event) => {
    exitWarningModal('modal-price-demo-aux-modal-container-01')
  })

  // Get all data from cache
  fetchInitialData()

  $.when(
    $.ajax({
      url: productUrl
    }),
    $.ajax({
      url: companyUrl
    }),
    $.ajax({
      url: productPmsUrl
    })
  ).then(function (productData, companyData, productPmsData) {
    window.htr_showModalPriceDemo.productData = productData[0] ? productData[0] : []
    window.htr_showModalPriceDemo.companyData = companyData[0] ? companyData[0] : []
    window.htr_showModalPriceDemo.productPmsData = productPmsData[0] ? productPmsData[0] : []
    window.htr_showModalPriceDemo.step = 0

    if(window.Laravel.isHotelier && productData[0].category_id && window.Laravel.apiToken) {
      $.ajax({
        url: `/api/categories/${ productData[0].category_id }/projects`,
        async: false,
        timeout: 20000,
        beforeSend: (xhr) => {
          xhr.setRequestHeader('Authorization', `Bearer ${ window.Laravel.apiToken }`)
        },
        success: (data) => {
          if(data.uuid) {
            window.location.href = `/projects/${data.uuid}`
            window.htr_closeModal(modalContainerId)
          }
        }
      })
    }

    let readyToNext = false
    printModalHeader(window.htr_showModalPriceDemo.productData, window.htr_showModalPriceDemo.companyData, stringMessages)
    readyToNext = printModalContent(window.htr_showModalPriceDemo.productData, window.htr_showModalPriceDemo.companyData, window.htr_showModalPriceDemo.productPmsData, stringMessages)
    printModalFooter(window.htr_showModalPriceDemo.productData, window.htr_showModalPriceDemo.companyData, stringMessages, readyToNext)
    $('#modal-price-demo-spinner-01').hide()

    if (preSelectedPropertySize) {
        $('#modal-price-demo-container-info-content-select-item-property-size').val(preSelectedPropertySize)
        $('#modal-price-demo-container-info-content-select-item-region').removeAttr('disabled');
        $('#modal-price-demo-container-info-content-select-item-region').parent().removeAttr('disabled');

    }

    $('.modal-price-demo-wrapper').on('click', function (e) {
      if (e.target !== this) {
        return
      }
      exitWarningModal('modal-price-demo-aux-modal-container-01')
    })
  }, function () {
    alert('Error')
  })

  function fetchInitialData () {
    //Get all stored data and verify if it should be printed
    let visitorData = window.getVisitorData()

    if (typeof visitorData !== 'object') {
      return 0
    }

    let personalizedData = checkValuesFromPersonalizedData(visitorData)

    if (visitorData.hasOwnProperty('category_id') &&
      window.htr_showModalPriceDemo.productData?.category?.id === visitorData.category_id) {
      window.htr_showModalPriceDemo.selectedData = visitorData
    } else if (visitorData.hasOwnProperty('email')) {

      //This part will fill user data if the user has already filled the form or if is authenticated
      if (window.htr_showModalPriceDemo.hasOwnProperty('selectedData') &&
        typeof window.htr_showModalPriceDemo.selectedData === 'object' &&
        window.htr_showModalPriceDemo.selectedData !== null) {

        window.htr_showModalPriceDemo.selectedData.email = visitorData.email
        window.htr_showModalPriceDemo.selectedData.first_name = visitorData.first_name
        window.htr_showModalPriceDemo.selectedData.last_name = visitorData.last_name
        window.htr_showModalPriceDemo.selectedData.title = visitorData.title
        window.htr_showModalPriceDemo.selectedData.company = visitorData.company
        window.htr_showModalPriceDemo.selectedData.phone = visitorData.phone
      } else {
        window.htr_showModalPriceDemo.selectedData = {
          email: visitorData.email,
          first_name: visitorData.first_name,
          last_name: visitorData.last_name,
          title: visitorData.title,
          company: visitorData.company,
          phone: visitorData.phone
        }
      }

      if (Object.keys(personalizedData).length > 0) {
        Object.assign(window.htr_showModalPriceDemo.selectedData, personalizedData)
      }
    }
    //If user has visitor data, but haven't selected items yet, fill the selected items
    if (!window.htr_showModalPriceDemo.hasOwnProperty('selectedData') || window.htr_showModalPriceDemo.selectedData === null) {
      if (Object.keys(personalizedData).length > 0) {
        window.htr_showModalPriceDemo.selectedData = personalizedData
      }
    }
  }

  function checkValuesFromPersonalizedData (visitorData) {
    let personalizedData = {}
    if (visitorData?.hotel_type?.length > 0) {
      personalizedData.hotel_type = visitorData.hotel_type
    }

    if (visitorData?.property_size?.length > 0) {
      personalizedData.property_size = visitorData.property_size
    }

    if (visitorData?.region?.length > 0) {
      personalizedData.region = visitorData.region
    }

    if (visitorData?.pms_id?.length > 0) {
      personalizedData.pms_id = visitorData.pms_id
      personalizedData.pms_other = null
    }

    return personalizedData
  }

  /* HEADER PRINT FUNCTIONS */
  function printModalHeader (productInfo, companyData, customizedMessage, step = null) {
    let htmlContent
    let headerElement = $('#modal-price-demo-container-info-header-00')
    if(!headerElement.length) {
      return 0
    }

    htmlContent = `
    <div class="modal-price-demo-container-info-header-company-info">
      <div class="modal-price-demo-container-info-header-image">
        <img src="${ companyData.logo ? companyData.logo : '/images/company-default-logo.png'}" height="55" width="55" alt="Company logo">
      </div>
      <span id="modal-price-demo-container-info-header-company-info-message-00">
        ${htrLanguage.company.personalize_your || 'personalize_your'} ${ companyData.name ? companyData.name : '' } ${ typeof customizedMessage == 'string' ? customizedMessage.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''}
      </span>
    </div>
      `

    headerElement.append(htmlContent)
  }

  function printModalHeaderTopMatches (region) {
    //Hide default header, same div will be used on step 3
    let defaultHeaderContainer = $('.modal-price-demo-container-info-header-company-info')

    if (defaultHeaderContainer && defaultHeaderContainer.length > 0) {
      defaultHeaderContainer.hide()
    }
    let htmlContent
    let headerElement = $('#modal-price-demo-container-info-header-00')
    if(!headerElement.length) {
      return 0
    }
    const regionObj = window.Laravel.dictionaries['markets_new'].find(r => r.value === region);
    let getFromOtherVendors = {
      'request_demo': htrLanguage.company.get_demos_from_other_vendors || 'Get a few demos from other top vendors to compare',
      'request_quote': htrLanguage.company.get_quotes_from_other_vendors || 'Get a few price quotes from other top vendors to compare',
      'alert_not_connected_with_hotels': htrLanguage.company.alert_not_connected_with_hotels.replace('{{company}}', window.htr_showModalPriceDemo.companyData.name || '{{company}}').replace('{{region}}', regionObj.label || '{{region}}') || 'ALERT: It looks like {{company}} may not be connecting with hotels in {{region}}, here are other top vendors who are:'
    }

    let weRecommendGetting = {
      'request_demo': htrLanguage.company.we_recommend_getting_demos || 'We recommend getting demos from at least two other vendors.',
      'request_quote': htrLanguage.company.we_recommend_getting_quotes || 'We recommend getting quotes from at least two other vendors.'
    }
    htmlContent = `
      <div class="modal-price-demo-container-info-header-recommended-products-content">
        <p> ${htrLanguage.company.recommended || 'Recommended'} </p>
        <p> ${getFromOtherVendors.hasOwnProperty(window.htr_showModalPriceDemo.requestType) ? getFromOtherVendors[window.htr_showModalPriceDemo.requestType] : ''} </p>
        <p> ${weRecommendGetting.hasOwnProperty(window.htr_showModalPriceDemo.requestType) ? weRecommendGetting[window.htr_showModalPriceDemo.requestType] : ''} </p>
        ${window.htr_showModalPriceDemo.autodecline ?
          `<div class="modal-price-alert-container">
            <i class="far fa-bell" aria-hidden="true"></i>
            <p class="modal-price-demo-alert-not-connected">
              ${weRecommendGetting.hasOwnProperty(window.htr_showModalPriceDemo.requestType) ? getFromOtherVendors['alert_not_connected_with_hotels'] : ''}
            </p>
          </div>`
        : ''}
      </div>
    `;

    headerElement.append(htmlContent)
  }

  function printModalHeaderDetails () {

    let defaultHeaderContainer = $('.modal-price-demo-container-info-header-company-info')
    let topMatchesHeaderContent = $('.modal-price-demo-container-info-header-recommended-products-content')

    //Remove step 2 header
    if (topMatchesHeaderContent && topMatchesHeaderContent.length > 0) {
      topMatchesHeaderContent.remove()
    }
    //show default header
    if (defaultHeaderContainer && defaultHeaderContainer.length > 0) {
      defaultHeaderContainer.show()
    }


    let messages = {
      'request_demo': (htrLanguage.company.demo || 'demo').toLowerCase(),
      'request_quote': (htrLanguage.company.price_quote || 'price_quote').toLowerCase()
    }

    let stringMessages

    if(window.htr_showModalPriceDemo.requestType && typeof window.htr_showModalPriceDemo.requestType == 'string'){
      stringMessages = messages.hasOwnProperty(window.htr_showModalPriceDemo.requestType) ? messages[window.htr_showModalPriceDemo.requestType] : ''
    }
    let headerSpanElement = $('#modal-price-demo-container-info-header-company-info-message-00')
    let productData = window.htr_showModalPriceDemo.productData
    let stringContent = `${htrLanguage.company.header_step3_modal_1 || 'header_step3_modal_1'} ${ productData.company.name } ${ stringMessages } ${htrLanguage.company.header_step3_modal_2 || 'header_step3_modal_2'}`
    headerSpanElement.text(stringContent)
  }

  /* CONTENT PRINT FUNTIONS*/
  function printModalContent (productInfo, companyData, productPmsData, customizedMessage, step = null) {
    let htmlContent
    let contentElement = $('#modal-price-demo-container-info-content-00')

    if (!contentElement.length) {
      return 0
    }


    // HOTEL TYPES
    let arrayHotelTypes = (window.Laravel) ? sortAlphabet(window.Laravel.dictionaries['hotel_types']) : []
    arrayHotelTypes = arrayHotelTypes.map(hotelType => ({ ...hotelType, label: htrLanguage.company['hotel_type_' + hotelType.value] || 'hotel_type_' }))
    let htmlContentHotelTypes = ''
    let htmlContentHotelTypesObject = {}
    Object.assign(htmlContentHotelTypesObject, printModalContentSelectInput(arrayHotelTypes, 'hotel_type'))

    htmlContentHotelTypes = `
        <div class="modal-price-demo-container-info-content-select-item">
          <select id="modal-price-demo-container-info-content-select-item-hotel-type" name="hotel_type">
            ${ htmlContentHotelTypesObject.content }
          </select>
        </div>`

    // PROPERTY SIZE
    let arrayNumberOfRooms = (window.Laravel) ? window.Laravel.dictionaries['translated_hotel_property_size_dynamic'] : []
    arrayNumberOfRooms = Object.keys(arrayNumberOfRooms).map(numberOfRoom =>
      ({ value: numberOfRoom, label: arrayNumberOfRooms[numberOfRoom] || 'property_size_' }
    ))

    let htmlContentNumberOfRooms = ''
    let htmlContentNumberOfRoomsObject = {}
    Object.assign(htmlContentNumberOfRoomsObject, printModalContentSelectInput(arrayNumberOfRooms, 'property_size'))

    if (htmlContentHotelTypesObject.optionSeletedfound
      && htmlContentNumberOfRoomsObject.optionSeletedfound) {
      htmlContentNumberOfRooms = `
        <div class="modal-price-demo-container-info-content-select-item">
          <select id="modal-price-demo-container-info-content-select-item-property-size" name="property_size">
            ${ htmlContentNumberOfRoomsObject.content }
          </select>
        </div>`
    } else {
      htmlContentNumberOfRooms = `
        <div class="modal-price-demo-container-info-content-select-item" disabled="disabled">
          <select id="modal-price-demo-container-info-content-select-item-property-size" name="property_size" disabled="disabled">
            ${ htmlContentNumberOfRoomsObject.content }
          </select>
        </div>`
    }

    // REGIONS
    let arrayRegions = (window.Laravel) ? window.Laravel.dictionaries['markets_new'] : []
    arrayRegions = arrayRegions.map(region => ({ ...region, label: htrLanguage.company['region_' + region.value] || 'region_' }))
    let htmlContentRegions = ''
    let htmlContentRegionsObject = {}
    Object.assign(htmlContentRegionsObject, printModalContentSelectInput(arrayRegions, 'region'))

    if (htmlContentHotelTypesObject.optionSeletedfound
      && htmlContentNumberOfRoomsObject.optionSeletedfound
      && htmlContentRegionsObject.optionSeletedfound ) {
      htmlContentRegions = `
        <div class="modal-price-demo-container-info-content-select-item">
          <select id="modal-price-demo-container-info-content-select-item-region" name="region">
            ${ htmlContentRegionsObject.content }
          </select>
        </div>`
    } else {
      htmlContentRegions = `
        <div class="modal-price-demo-container-info-content-select-item" disabled="disabled">
          <select id="modal-price-demo-container-info-content-select-item-region" name="region" disabled="disabled">
            ${ htmlContentRegionsObject.content }
          </select>
        </div>`
    }

    // PMS PRODUCTS
    let arrayPmsProducts = (productPmsData && Array.isArray(productPmsData)) ? productPmsData : []
    arrayPmsProducts = arrayPmsProducts.map(product => ({ ...product, name: product.name }))
    let pmsModalText = ''
    if (productInfo
      && productInfo.category
      && (productInfo.category.id === 46 || productInfo.category.id === 74)) {
      pmsModalText = htrLanguage.company.content_step1_pms_text_2 || 'content_step1_pms_text_2'
    } else {
      pmsModalText = htrLanguage.company.content_step1_pms_text_1 || 'content_step1_pms_text_1'
    }

    let htmlContentPmsProducts = ''
    let dropDownListObject = printDropdownList(arrayPmsProducts)
    let hasPmsContentEnabled = false

    if (htmlContentHotelTypesObject.optionSeletedfound
      && htmlContentNumberOfRoomsObject.optionSeletedfound
      && htmlContentRegionsObject.optionSeletedfound) {
      hasPmsContentEnabled = true
    }

    if ((window.htr_showModalPriceDemo?.selectedData?.pms_id ||
      window.localStorage.getItem('pms_id'))) {
      if (dropDownListObject.key) {
        htmlContentPmsProducts += `
          <div class="modal-price-demo-container-info-content-PMS-section-search">
            <input id="modal-price-demo-container-info-content-PMS-section-search-compatible-pms" type="text" name="pms_id" placeholder="${ htrLanguage.company.find_your_pms || 'find_your_pms' }" key="${ dropDownListObject.key }" value="${ dropDownListObject.value }" ${ hasPmsContentEnabled ? '' : 'disabled="disabled"'}>
          </div>
            <ul id="modal-price-demo-container-info-content-PMS-section-search-dropdown-00" class="modal-price-demo-container-info-content-PMS-section-search-dropdown" style="display: none">
          ${ dropDownListObject.content }`
      } else {
        htmlContentPmsProducts += `
          <div class="modal-price-demo-container-info-content-PMS-section-search">
            <input id="modal-price-demo-container-info-content-PMS-section-search-compatible-pms" type="text" name="pms_id" placeholder="${ htrLanguage.company.find_your_pms || 'find_your_pms' }" ${ hasPmsContentEnabled ? '' : 'disabled="disabled"'}>
          </div>
            <ul id="modal-price-demo-container-info-content-PMS-section-search-dropdown-00" class="modal-price-demo-container-info-content-PMS-section-search-dropdown" style="display: none">
          ${ dropDownListObject.content }`
      }

    } else {
        htmlContentPmsProducts += `
          <div class="modal-price-demo-container-info-content-PMS-section-search" disabled="disabled">
            <input id="modal-price-demo-container-info-content-PMS-section-search-compatible-pms" type="text" name="pms_id" placeholder="${ htrLanguage.company.find_your_pms || 'find_your_pms' }" disabled="disabled">
          </div>
            <ul id="modal-price-demo-container-info-content-PMS-section-search-dropdown-00" class="modal-price-demo-container-info-content-PMS-section-search-dropdown" style="display: none">
          ${ dropDownListObject.content }`
    }

    // MULTI PROPERTY
    let htmlContentMultiProperty = ''

    let ls_multi_property = null
    if (window.localStorage.getItem('multi_property')) {
      ({
        ls_multi_property
      } = JSON.parse(window.localStorage.getItem('multi_property')))
    }


    if (hasPmsContentEnabled
        && ((window.htr_showModalPriceDemo.selectedData
          && window.htr_showModalPriceDemo.selectedData.hasOwnProperty('multi_property')
          && window.htr_showModalPriceDemo.selectedData.multi_property
        && window.htr_showModalPriceDemo.selectedData.multi_property !== null)
        || ls_multi_property)) {

      if((window.htr_showModalPriceDemo.selectedData && window.htr_showModalPriceDemo.selectedData.multi_property) || ls_multi_property) {
        htmlContentMultiProperty += `
          <span
            id="modal-price-demo-container-info-content-PMS-section-switch-msg-no"
            class="modal-price-demo-container-info-content-PMS-section-switch-msg">
            ${ htrLanguage.company.no || 'no' }
          </span>
          <label class="modal-price-demo-container-info-content-PMS-section-switch">
            <input id="modal-price-demo-container-info-content-PMS-section-switch-toggle-00" type="checkbox" value="multi_property" checked="true">
            <span class="modal-price-demo-container-info-content-PMS-section-switch-slider modal-price-demo-container-info-content-PMS-section-switch-round"></span>
          </label>
          <span
            id="modal-price-demo-container-info-content-PMS-section-switch-msg-yes"
            class="modal-price-demo-container-info-content-PMS-section-switch-msg modal-price-demo-container-info-content-PMS-section-switch-msg-yes-selected">
            ${ htrLanguage.company.yes || 'yes' }
          </span>`

      } else {
            htmlContentMultiProperty += `
          <span
            id="modal-price-demo-container-info-content-PMS-section-switch-msg-no"
            class="modal-price-demo-container-info-content-PMS-section-switch-msg modal-price-demo-container-info-content-PMS-section-switch-msg-no-selected">
            ${ htrLanguage.company.no || 'No' }
          </span>
          <label class="modal-price-demo-container-info-content-PMS-section-switch">
            <input id="modal-price-demo-container-info-content-PMS-section-switch-toggle-00" type="checkbox" value="multi_property">
            <span class="modal-price-demo-container-info-content-PMS-section-switch-slider modal-price-demo-container-info-content-PMS-section-switch-round"></span>
          </label>
          <span
            id="modal-price-demo-container-info-content-PMS-section-switch-msg-yes"
            class="modal-price-demo-container-info-content-PMS-section-switch-msg">
            ${ htrLanguage.company.yes || 'Yes' }
          </span>`
      }
    } else {
            htmlContentMultiProperty += `
          <span
            id="modal-price-demo-container-info-content-PMS-section-switch-msg-no"
            class="modal-price-demo-container-info-content-PMS-section-switch-msg modal-price-demo-container-info-content-PMS-section-switch-msg-no-selected">
            ${ htrLanguage.company.no || 'no' }
          </span>
          <label class="modal-price-demo-container-info-content-PMS-section-switch">
            <input id="modal-price-demo-container-info-content-PMS-section-switch-toggle-00" type="checkbox" disabled="disabled" value="multi_property">
            <span class="modal-price-demo-container-info-content-PMS-section-switch-slider modal-price-demo-container-info-content-PMS-section-switch-round"></span>
          </label>
          <span
            id="modal-price-demo-container-info-content-PMS-section-switch-msg-yes"
            class="modal-price-demo-container-info-content-PMS-section-switch-msg">
            ${ htrLanguage.company.yes || 'yes' }
          </span>`
    }



    htmlContent += `
            </ul>
            <div class="modal-price-demo-container-info-content-PMS-section-search-dropdown-full-screen" style="display: none"></div>`

    htmlContent = `
    <span class="modal-price-demo-container-info-content-title modal-price-demo-margin-top-33">
      ${ htrLanguage.company.hotel_attributes || 'hotel_attributes' }
    </span>
    <span class="modal-price-demo-container-info-content-subtitle">
      ${ htrLanguage.company.content_step1_price_demo || 'content_step1_price_demo' } ${ customizedMessage }.
    </span>
    <div class="modal-price-demo-container-info-content-forms">
      <div class="modal-price-demo-flex-column">
        <span class="modal-price-demo-container-info-content-select-titles">
          ${ htrLanguage.company.property_type || 'property_type' }
        </span>
        ${ htmlContentHotelTypes }
      </div>
      <div class="modal-price-demo-flex-column">
        <span class="modal-price-demo-container-info-content-select-titles">
          ${ htrLanguage.company.number_of_rooms || 'number_of_rooms' }
        </span>
        ${ htmlContentNumberOfRooms }
      </div>
      <div class="modal-price-demo-flex-column">
        <span class="modal-price-demo-container-info-content-select-titles">
          ${ htrLanguage.company.region || 'region' }
        </span>
        ${ htmlContentRegions }
      </div>
    </div>
    <div class="modal-price-demo-flex-column modal-price-demo-container-info-content-PMS-section">
      <div class="modal-price-demo-container-info-content-PMS-section-form">
        <div class="modal-price-demo-flex-column">
          <span class="modal-price-demo-container-info-content-title">
            ${ htrLanguage.company.property_management_system || 'property_management_system' }
          </span>
          <span class="modal-price-demo-container-info-content-subtitle">
            ${ pmsModalText }
          </span>
        </div>
        <div class="modal-price-demo-max-height-65">
          ${ htmlContentPmsProducts }
        </div>
      </div>
      <div class="modal-price-demo-container-info-content-MPF-section-form">
        <div class="modal-price-demo-flex-column">
          <span class="modal-price-demo-container-info-content-title">
            ${ htrLanguage.company.multi_property_functionality || 'multi_property_functionality' }
          </span>
          <span class="modal-price-demo-container-info-content-subtitle">
            ${ htrLanguage.company.will_you_need_multi_property || 'will_you_need_multi_property' }
          </span>
        </div>
        <div>
          ${ htmlContentMultiProperty }
        </div>
      </div>
    </div>
      `

    contentElement.append(htmlContent)

    let selectItems = $('.modal-price-demo-container-info-content-select-item > select')
    let selectSwitchItem = $('#modal-price-demo-container-info-content-PMS-section-switch-toggle-00')
    let inputSearchItem = $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms')

    selectItems.change((eventItem) => {
      enableModalContentNextInput(eventItem)
    })

    selectItems.focus((eventItem) => {
      enableModalContentNextInput(eventItem)
    })


    selectSwitchItem.change((eventItem) => {
      let itemElement = $(eventItem.target)
      if(itemElement.prop('checked')) {
        $('#modal-price-demo-container-info-content-PMS-section-switch-msg-yes').addClass('modal-price-demo-container-info-content-PMS-section-switch-msg-yes-selected')
        $('#modal-price-demo-container-info-content-PMS-section-switch-msg-no').removeClass('modal-price-demo-container-info-content-PMS-section-switch-msg-no-selected')
      } else {
        $('#modal-price-demo-container-info-content-PMS-section-switch-msg-yes').removeClass('modal-price-demo-container-info-content-PMS-section-switch-msg-yes-selected')
        $('#modal-price-demo-container-info-content-PMS-section-switch-msg-no').addClass('modal-price-demo-container-info-content-PMS-section-switch-msg-no-selected')
      }
    })

    inputSearchItem.click( (event) => {

      let dropdownItem = $('#modal-price-demo-container-info-content-PMS-section-search-dropdown-00')
      let dropdownList = window.htr_showModalPriceDemo.productPmsData ? window.htr_showModalPriceDemo.productPmsData : []
      dropdownList = dropdownList.map(product => ({ ...product, name: product.name }))

      if($(event.target).val().length > 0) {
        let stringToCompare = $(event.target).val() == '' ? null : $(event.target).val()
        let filteredList = filterArrayList(dropdownList, stringToCompare)

        dropdownItem.empty()
        dropdownItem.append(printDropdownList(filteredList).content)
      } else {
        dropdownItem.empty()
        dropdownItem.append(printDropdownList(dropdownList).content)
      }
        dropdownItem.show()

      $('.modal-price-demo-container-info-content-PMS-section-search-dropdown-full-screen').show()
      $('.modal-price-demo-container-info-footer-next-button').removeAttr('disabled')
    })

    inputSearchItem.keyup( (event) => {
      let inputSearchItem = $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms')
      let dropdownItem = $('#modal-price-demo-container-info-content-PMS-section-search-dropdown-00')
      let dropdownList = window.htr_showModalPriceDemo.productPmsData ? window.htr_showModalPriceDemo.productPmsData : []
      dropdownList = dropdownList.map(product => ({ ...product, name: product.name }))
      let stringToCompare = inputSearchItem.val() == '' ? null : inputSearchItem.val()
      let filteredList = filterArrayList(dropdownList, stringToCompare)

      dropdownItem.empty()
      dropdownItem.hide()
      if(inputSearchItem.val() == '') {
        $('.modal-price-demo-container-info-content-PMS-section-search-dropdown-full-screen').hide()
        dropdownItem.append(printDropdownList(filteredList).content)
      } else if(filteredList.length > 0) {
        $('.modal-price-demo-container-info-content-PMS-section-search-dropdown-full-screen').hide()
        dropdownItem.append(printDropdownList(filteredList).content)
      } else if(filteredList.length == 0) {
        let htmlDropdownContent = ''
        htmlDropdownContent += `
              <li key="other" value="${ stringToCompare }">
                <p>${ htrLanguage.company.cant_find_your_pms || 'cant_find_your_pms' }</p>
                <p>${ htrLanguage.company.click_here || 'click_here' }</p>
              </li>`
        dropdownItem.append(htmlDropdownContent)
      }
      dropdownItem.show()
    })

    return hasPmsContentEnabled
  }

  function enableModalContentNextInput (eventItem) {
    let itemElement = $(eventItem.target)
    let parentElemt = itemElement.parent()
    let selectorId = itemElement.attr('id')

    if (eventItem.type === 'change') {
      parentElemt.toggleClass('changed')
    }

    if (eventItem.type === 'change' ||
      (eventItem.type === 'focus') && itemElement.val() != null) {
      switch (selectorId) {
        case 'modal-price-demo-container-info-content-select-item-hotel-type':
          $('#modal-price-demo-container-info-content-select-item-property-size').removeAttr('disabled');
          $('#modal-price-demo-container-info-content-select-item-property-size').parent().removeAttr('disabled');
          $('#modal-price-demo-container-info-content-select-item-property-size').focus();
          break;
        case 'modal-price-demo-container-info-content-select-item-property-size':
          $('#modal-price-demo-container-info-content-select-item-region').removeAttr('disabled');
          $('#modal-price-demo-container-info-content-select-item-region').parent().removeAttr('disabled');
          $('#modal-price-demo-container-info-content-select-item-region').focus();
          break;
        case 'modal-price-demo-container-info-content-select-item-region':
          $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').removeAttr('disabled');
          $('#modal-price-demo-container-info-content-PMS-section-switch-toggle-00').removeAttr('disabled');
          $('.modal-price-demo-container-info-footer-next-button').removeAttr('disabled');
          $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').parent().removeAttr('disabled');
          $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').focus();
          break;
        default:
          console.log('Id: ' + selectorId + ' Not found')
      }
    }
  }

  function printModalContentTopMatches () {

    let htmlContentItem
    let htmlContent
    let spinnerElement = $('#modal-price-demo-spinner-02')

    if($('#modal-price-demo-container-info-content-00').length && window.htr_showModalPriceDemo.productData) {
      htmlContentItem = $('#modal-price-demo-container-info-content-00')
    }else {
      return 0
    }

    let hotelType = null
    let propertySize = null
    let regionData = null
    let compatiblePms = null
    let compatiblePmsOtherOption = null
    let multiPropertyFunctionality = null

    if ($('#modal-price-demo-container-info-content-select-item-hotel-type').length) {
      hotelType = $('#modal-price-demo-container-info-content-select-item-hotel-type').children('option:selected').val()
      window.htr_showModalPriceDemo.HotelTypeDescription = $('#modal-price-demo-container-info-content-select-item-hotel-type').children('option:selected').text()
    }

    if ($('#modal-price-demo-container-info-content-select-item-property-size').length) {
      propertySize = $('#modal-price-demo-container-info-content-select-item-property-size').children('option:selected').val()
    }

    if ($('#modal-price-demo-container-info-content-select-item-region').length) {
      regionData = $('#modal-price-demo-container-info-content-select-item-region').children('option:selected').val()
    }

    if ($('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').length) {
      if ($('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').attr('key') === 'other') {
        compatiblePms = 'other'
        compatiblePmsOtherOption = $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').val()
      } else {
        compatiblePms = $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').attr('key')
        compatiblePmsOtherOption = null
      }
    }

    if ($('#modal-price-demo-container-info-content-PMS-section-switch-toggle-00').length) {
      multiPropertyFunctionality = $('#modal-price-demo-container-info-content-PMS-section-switch-toggle-00').prop('checked')
    }

    if (window.htr_showModalPriceDemo.selectedData == undefined
      || (!window.htr_showModalPriceDemo.hasOwnProperty('selectedData')
      && typeof window.htr_showModalPriceDemo.selectedData != 'object'
      && !window.htr_showModalPriceDemo.selectedData.hasOwnProperty('category_id')
      && !window.htr_showModalPriceDemo.selectedData.category_id)) {
      window.htr_showModalPriceDemo.selectedData = {
        category_id: null,
        city: null,
        company: null,
        pms_id: null,
        pms_other: null,
        country: null,
        email: null,
        first_name: null,
        hotel_type: null,
        last_name: null,
        multi_property: null,
        phone: null,
        id_country: null,
        product_id: null,
        property_size: null,
        question_asked: null,
        region: null,
        source: window.htr_showModalPriceDemo.requestType,
        state: null,
        title: null,
        website: null,
        preferred_time: null,
        preferred_date: null,
      }
    }

    window.htr_showModalPriceDemo.selectedData.category_id = window.htr_showModalPriceDemo.productData.category_id
    window.htr_showModalPriceDemo.selectedData.pms_id = compatiblePms
    window.htr_showModalPriceDemo.selectedData.pms_other = compatiblePmsOtherOption
    window.htr_showModalPriceDemo.selectedData.hotel_type = hotelType
    window.htr_showModalPriceDemo.selectedData.multi_property = multiPropertyFunctionality
    window.htr_showModalPriceDemo.selectedData.product_id = window.htr_showModalPriceDemo.productData.product_id || window.htr_showModalPriceDemo.productData.id
    window.htr_showModalPriceDemo.selectedData.property_size = propertySize
    window.htr_showModalPriceDemo.selectedData.region = regionData
    window.htr_showModalPriceDemo.selectedData.source = window.htr_showModalPriceDemo.requestType
    window.htr_showModalPriceDemo.selectedData.preferred_time = window.htr_showModalPriceDemo.prefered_time
    window.htr_showModalPriceDemo.selectedData.preferred_date = window.htr_showModalPriceDemo.prefered_date
    //Store actual visitor data
    window.storeVisitorFields(window.htr_showModalPriceDemo.selectedData)


    let productsWhithEnabledDirectLeads
    let similarTopProductsUrl = `/products/${window.htr_showModalPriceDemo.productData.id}/similar-top-products`
    if (similarTopProductsUrl && window.htr_showModalPriceDemo?.selectedData?.email) {
      similarTopProductsUrl += `?email=${window.htr_showModalPriceDemo.selectedData.email}`
    }
    let checkAutodeclineUrl = `/api/products-check-auto-decline?product_id=${window.htr_showModalPriceDemo.productData.id}&property_size=${propertySize}&region=${regionData}`

    let leadRequestCreateUrl
    if (!window.htr_showModalPriceDemo.leads) {
      leadRequestCreateUrl = `/api/lead-requests`
    } else {
      leadRequestCreateUrl = `/api/lead-requests/${ window.htr_showModalPriceDemo.leads.id }`
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': window.Laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + window.Laravel.apiToken,
      },
    })

    $.when(
      $.ajax({
        url: similarTopProductsUrl
      }),
      $.ajax({
        method: window.htr_showModalPriceDemo.leads ? 'PUT' : 'POST',
        url: leadRequestCreateUrl,
        data: window.htr_showModalPriceDemo.selectedData
      }),
      $.ajax({
        url: checkAutodeclineUrl
      })
    ).then(function (similarTopProducts, lead, checkAutodecline) {
      let isPremium
      if (checkAutodecline && checkAutodecline[0] && checkAutodecline[0].value) {
        window.htr_showModalPriceDemo.autodecline = true
      } else {
        window.htr_showModalPriceDemo.autodecline = false
      }
      printModalHeaderTopMatches(window.htr_showModalPriceDemo.selectedData.region)
      $('#modal-price-demo-spinner-02').hide()
      window.htr_showModalPriceDemo.leads = lead[0]
      isPremium = Array.isArray(window.htr_showModalPriceDemo.companyData.rules) ? window.htr_showModalPriceDemo.companyData.rules.find((element) => element === 'premium_profiles') : 0

      if (lead.user && lead.user.verified) window.htr_showModalPriceDemo.userVerified = true

      if (!window.Laravel.userId || !window.Laravel.isPremium) {
        window.htr_showModalPriceDemo.step = 1
      } else if (window.htr_showModalPriceDemo.userVerified) {
        window.htr_showModalPriceDemo.step = 1
      } else {
        window.htr_showModalPriceDemo.selectedData.email = lead.user ? lead.user.email : null
        window.htr_showModalPriceDemo.step = 4
      }

      if (listOfPreselectedTopProducts !== null && Array.isArray(listOfPreselectedTopProducts) && listOfPreselectedTopProducts.length > 1) {
        $('.modal-price-demo-container-info-footer-next-button').trigger('click')
      }

      productsWhithEnabledDirectLeads = similarTopProducts[0].filter((product) => product.company.disable_direct_leads === 0)
      productsWhithEnabledDirectLeads = productsWhithEnabledDirectLeads.map(product => ({ ...product, name: product.name }))
      let htmlTopProducts = printModalTopMatchesProducts(productsWhithEnabledDirectLeads)

      if ($('#modal-price-demo-container-info-content-top-products-00').length) $('#modal-price-demo-container-info-content-top-products-00').append(htmlTopProducts)
    }, function () {
      alert('Error')
    })


    htmlContentItem.empty()
    spinnerElement.show()

    htmlContent = `
      <div class="modal-price-demo-container-info-content-top-products-form">
        <div class="modal-price-demo-flex-row">
          <img src="/images/union-image.svg" height="26" width="38" alt="Union">
          <span class="modal-price-demo-container-info-content-top-title">${ htrLanguage.company.top_matches_for_you || 'top_matches_for_you' }</span>
        </div>
        <div id="modal-price-demo-container-info-content-top-products-00" class ="modal-price-demo-container-info-content-top-products">
        </div>
      </div>
    `

    htmlContentItem.append(htmlContent)
    $('body').on('click', '.modal-price-demo-container-info-content-top-products-checkbox', (event) => {
      let objectItems = $('.modal-price-demo-container-info-content-top-products-checkbox')
      let howManyClicked = 0
      let triggerToNext

      Object.keys(objectItems).forEach((key) => {
        if($(objectItems[key]).prop('checked')) {
          howManyClicked += 1
        }
      })

      if(window.htr_showModalPriceDemo.step == 1) {
        triggerToNext = 0
      }

      if(howManyClicked >= triggerToNext) {
        $('.modal-price-demo-container-info-footer-next-button').removeAttr('disabled')
      } else {
        $('.modal-price-demo-container-info-footer-next-button').attr('disabled', 'disabled')
      }

    })

  }


  function printModalContentDetails () {

    let htmlContent = ''
    let contentElement = $('#modal-price-demo-container-info-content-00')
    let productsSelected = $('.modal-price-demo-container-info-content-top-products-checkbox')
    let arrayTopProductsSelected = []
    let hasPreselectedTopProducts = false

    if (listOfPreselectedTopProducts !== null && Array.isArray(listOfPreselectedTopProducts) && listOfPreselectedTopProducts.length > 1) {
      hasPreselectedTopProducts = true
    }

    if (hasPreselectedTopProducts) {
      arrayTopProductsSelected = listOfPreselectedTopProducts.slice(1)
    } else {
      for (let i=0; i < productsSelected.length; i++) {
        if ($(productsSelected[i]).prop('checked')) {
          arrayTopProductsSelected.push($(productsSelected[i]).attr('value'))
        }
      }
    }

    window.htr_showModalPriceDemo.selectedTopProducts = arrayTopProductsSelected

    let hasSelectedData = window.htr_showModalPriceDemo.hasOwnProperty('selectedData') ? true : false

    let hasFirstName = false
    let hasLastName = false
    let hasTitle = false
    let hasCompany = false
    let hasEmail = false
    let hasPhone = false
    let hasConfirmationUrl = false
    let selectedHotelType = null

    if (hasSelectedData) {
      hasFirstName = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('first_name') ? true : false
      hasLastName = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('last_name') ? true : false
      hasTitle = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('title') ? true : false
      hasCompany = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('company') ? true : false
      hasEmail = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('email') ? true : false
      hasPhone = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('phone') ? true : false
      hasConfirmationUrl = window.htr_showModalPriceDemo.selectedData.hasOwnProperty('confirmation_url') ? true : false
      selectedHotelType =  window.htr_showModalPriceDemo.selectedData.hasOwnProperty('hotel_type') && window.htr_showModalPriceDemo.selectedData.hotel_type ?
          window.htr_showModalPriceDemo.selectedData.hotel_type : null
    }
    let ls_first_name = null
    let ls_last_name = null
    let ls_title = null
    let ls_company = null
    let ls_email = null
    let ls_phone = null
    let ls_confirmation_url = null
    if (window.localStorage.getItem('user_info')) {
      ({
        ls_first_name,
        ls_last_name,
        ls_title,
        ls_company,
        ls_email,
        ls_confirmation_url,
        ls_phone
      } = JSON.parse(window.localStorage.getItem('user_info')))
    }
    //FIRST NAME
    let htmlFirstName = ''
    if(hasFirstName &&  window.htr_showModalPriceDemo.selectedData.first_name != null) {

      htmlFirstName += `
              <input
              id="modal-price-demo-container-detail-first-name"
              name="first_name"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              value="${ window.htr_showModalPriceDemo.selectedData.first_name }">`
    } else if (ls_first_name) {
        htmlFirstName += `
        <input
        id="modal-price-demo-container-detail-first-name"
        name="first_name"
        class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
        type="text"
        value="${ ls_first_name }">`
    }else {

      htmlFirstName += `
              <input
              id="modal-price-demo-container-detail-first-name"
              name="first_name"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text">`
    }

    //LAST NAME
    let htmlLastName = ''
    if(hasLastName &&  window.htr_showModalPriceDemo.selectedData.last_name != null) {

      htmlLastName += `
              <input
              id="modal-price-demo-container-detail-last-name"
              name="last_name"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              value="${ window.htr_showModalPriceDemo.selectedData.last_name }">`
    } else if (ls_last_name) {
      htmlLastName += `
              <input
              id="modal-price-demo-container-detail-last-name"
              name="last_name"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              value="${ ls_last_name }">`
    } else {

      htmlLastName += `
              <input
              id="modal-price-demo-container-detail-last-name"
              name="last_name"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text">`
    }

    //JOB TITLE
    let htmlTitle = ''
    if(hasTitle &&  window.htr_showModalPriceDemo.selectedData.title != null) {

      htmlTitle += `
              <input
              id="modal-price-demo-container-detail-job-title"
              name="job_title"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              value="${ window.htr_showModalPriceDemo.selectedData.title }">`
    } else if (ls_title) {
      htmlTitle += `
              <input
              id="modal-price-demo-container-detail-job-title"
              name="job_title"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              value="${ ls_title }">`
    }else {

      htmlTitle += `
              <input
              id="modal-price-demo-container-detail-job-title"
              name="job_title"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text">`
    }

    //COMPANY
    let htmlCompany = ''
    if(hasCompany &&  window.htr_showModalPriceDemo.selectedData.company != null) {

      htmlCompany += `
              <input
              id="modal-price-demo-container-detail-company-hotel"
              name="company_hotel"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              placeholder="${ htrLanguage.home.enter_a_property_company_name || 'Enter a Property/Company name' }"
              value="${ window.htr_showModalPriceDemo.selectedData.company }">`;
    } else if (ls_company) {
      htmlCompany += `
              <input
              id="modal-price-demo-container-detail-company-hotel"
              name="company_hotel"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="text"
              placeholder="${ htrLanguage.home.enter_a_property_company_name || 'Enter a Property/Company name' }"
              value="${ ls_company }">`;
    }else {

      htmlCompany += `
              <input
              id="modal-price-demo-container-detail-company-hotel"
              name="company_hotel"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              placeholder="${ htrLanguage.home.enter_a_property_company_name || 'Enter a Property/Company name' }"
              type="text">`;
    }

    //EMAIL
    let htmlEmail = ''
    let verifiedEmail = null;
    const isVerified = window.creditPrefilledData.verified ? window.creditPrefilledData.verified : false;
    if (isVerified) {
      verifiedEmail = window.creditPrefilledData.email
    }
    if (verifiedEmail != null) {
      htmlEmail += `
            <input
              id="modal-price-demo-container-detail-work-email"
              autocomplete="off"
              placeholder="${ htrLanguage.quiz.bussiness_email || 'Business Email' }"
              name="email"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="email"
              value="${ verifiedEmail }"
              disabled>`
    } else if(hasEmail &&  window.htr_showModalPriceDemo.selectedData.email != null) {

      htmlEmail += `
              <input
              id="modal-price-demo-container-detail-work-email"
              autocomplete="off"
              placeholder="${ htrLanguage.quiz.bussiness_email || 'Business Email' }"
              name="email"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="email"
              value="${ window.htr_showModalPriceDemo.selectedData.email }">`
    } else if (ls_email) {
      htmlEmail += `
              <input
              id="modal-price-demo-container-detail-work-email"
              autocomplete="off"
              placeholder="Business Email"
              name="email"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="email"
              value="${ ls_email }">`
    } else {

      htmlEmail += `
              <input
              id="modal-price-demo-container-detail-work-email"
              autocomplete="off"
              placeholder="Business Email"
              name="email"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-large-input"
              type="email">`
    }
    // VERIFICATION SITE
    let htmlSite = '';
    let verifySiteMessage = '';
    let verifyPlaceHolder = '';

    if (htrLanguage.company) {
      if (selectedHotelType && selectedHotelType === 'vacation-rentals-villas') {
        verifySiteMessage = htrLanguage.company.verify_your_propertys_listing || 'verify_your_propertys_listing'
        verifyPlaceHolder = 'www.airbnb.com/listing/1234'
      } else {
        verifySiteMessage = htrLanguage.company.verify_site || 'verify_site'
        verifyPlaceHolder = 'www.mycompany.com'
      }
    }

    if(htmlSite &&  window.htr_showModalPriceDemo.selectedData.confirmation_url != null) {

      htmlSite += `
              <input
              id="modal-price-demo-container-detail-confirmation-url"
              name="confirmation_url"
              placeholder="${ verifyPlaceHolder }"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-x-large-input"
              type="text"
              value="${ window.htr_showModalPriceDemo.selectedData.confirmation_url }">`;
    } else if (ls_confirmation_url) {
      htmlSite += `
              <input
              id="modal-price-demo-container-detail-confirmation-url"
              name="confirmation_url"
              placeholder="${ verifyPlaceHolder }"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-x-large-input"
              type="text"
              value="${ ls_confirmation_url }">`;
    } else {

      htmlSite += `
              <input
              id="modal-price-demo-container-detail-confirmation-url"
              name="confirmation_url"
              placeholder="${ verifyPlaceHolder }"
              class="modal-price-demo-container-info-content-detail-default-properties-input modal-price-demo-container-info-content-detail-x-large-input"
              type="text">`;
    }
    //Phone
    let htmlPhoneNumber = ''
    let phoneDialCode = null
    let phoneCode = 'US'
    let phoneNumber = null
    let arrayPhone = null

    if (hasPhone && window.htr_showModalPriceDemo.selectedData.phone) {
      arrayPhone = window.htr_showModalPriceDemo.selectedData.phone.split(' ')
    } else if(hasPhone && ls_phone) {
          arrayPhone = ls_phone.split(' ')
    } else {
      arrayPhone = Object.values(dialCodes)[0].dial_code + ' '
      arrayPhone = arrayPhone.split(' ')

    }
    if(arrayPhone.length > 0) {
      phoneDialCode = arrayPhone.shift()
      let selectedCountry = Object.keys(dialCodes).find(key => dialCodes[key].dial_code === phoneDialCode)
      if (selectedCountry) {
        phoneCode = selectedCountry
      } else if (window.htr_showModalPriceDemo.selectedData.id_country) {
        phoneCode = window.htr_showModalPriceDemo.selectedData.id_country
      }
      phoneNumber = ''

      arrayPhone.forEach((item) => {
        phoneNumber += item + ' '
      })
      phoneNumber = phoneNumber.slice(0, -1)

    }

    htmlPhoneNumber += `
              <div class="modal-price-demo-input-container">
                <div class="modal-price-demo-select-wrapper" id="modal-price-demo-select-wrapper">
                  <div class="modal-price-demo-select-content" id="modal-price-demo-container-info-content-detail-select-content">
                    <div class="modal-price-demo-select-option-current modal-price-demo-container-info-content-detail-default-properties-input" id="modal-price-demo-container-detail-phone-country-code" ${ phoneDialCode ? `key="${ phoneDialCode }" code="${ phoneCode }"` : '' }>
                      <img class="modal-price-demo-select-flag" id="modal-price-demo-container-detail-flag-current" src="/images/flags_svg/${ phoneCode.toLowerCase() }.svg" />
                      <img class="modal-price-demo-select-arrow" id="modal-price-demo-container-detail-arrow-flag" src="/images/arrow-down-without-bar.svg">
                    </div>
                    <div class="modal-price-demo-select-options" id="modal-price-demo-container-info-content-detail-dropdown-00">`
  htmlPhoneNumber += printDialCodes(phoneCode)
  htmlPhoneNumber += `
                    </div>
                  </div>
                </div>
                <input
                  class="modal-price-demo-input-phone modal-price-demo-container-info-content-detail-default-properties-input"
                  ${ phoneNumber ? `
                    key="${ phoneNumber }"
                    value="${ phoneNumber }"
                  ` : '' }
                  type="tel"
                  placeholder="${ getExampleNumber(phoneCode.toUpperCase(), libPhoneNumberExamples).formatNational() }"
                  maxlenght="10"
                  id="modal-price-demo-container-detail-phone-number"
                  name="phone_number"
                >
              </div>`

    htmlContent = `
      <div class="modal-price-demo-container-info-content-detail-default-properties-container">
        <div class="modal-price-demo-container-info-content-detail-default-properties-row">
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ (htrLanguage.reports.first_name || 'first_name').charAt(0).toUpperCase() + (htrLanguage.reports.first_name || 'first_name').slice(1) }</span>
            <div>
              ${ htmlFirstName }
            </div>
          </div>
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ (htrLanguage.reports.last_name || 'last_name').charAt(0).toUpperCase() + (htrLanguage.reports.last_name || 'last_name').slice(1) }</span>
            <div>
              ${ htmlLastName }
            </div>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-detail-default-properties-row">
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ htrLanguage.company.job_title || 'job_title' }</span>
            <div>
              ${ htmlTitle }
            </div>
          </div>
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ htrLanguage.company.hotel_or_company || 'hotel_or_company' }</span>
            <div>
              ${ htmlCompany }
            </div>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-detail-default-properties-row">
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ htrLanguage.company.work_email || 'work_email' }</span>
            <form autocomplete="off">
              <input autocomplete="false" name="hidden" type="text" class="hidden">
              ${ htmlEmail }
            </form>
            <span class="modal-price-demo-container-info-content-detail-title-invalid-field hidden"
            id="modal-price-demo-container-detail-work-email-invalid-email">${ htrLanguage.company.work_email_fail || 'work_email_fail' }</span>
          </div>
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ htrLanguage.company.phone_number || 'phone_number' }</span>
            <div class="modal-price-demo-container-info-content-detail-default-properties-row-phone">
              ${ htmlPhoneNumber }
            </div>
            <span class="modal-price-demo-container-info-content-detail-title-invalid-field hidden"
            id="modal-price-demo-container-detail-phone-number-invalid-email">${ htrLanguage.company.phone_number_fail || 'phone_number_fail' }</span>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-detail-default-properties-row" id="tip-warning-for-email">
        </div>
        <div class="modal-price-demo-container-info-content-detail-default-properties-row" id="tip-warning-for-validate-url">
          <div class="modal-price-demo-flex-column">
            <span class="modal-price-demo-container-info-content-detail-title">${ verifySiteMessage }</span>
            <div>
              ${ htmlSite }
            </div>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-detail-default-properties-row">
          <img src="/images/book_icon.blue.svg" height="22" width="24" alt="${ htrLanguage.company.full_star_blue || 'full_star_blue' }">
          <div class="modal-price-demo-flex-column modal-price-demo-container-info-content-detail-bonus-container">
            <span class="modal-price-demo-container-info-content-detail-bonus-title">${ htrLanguage.company.bonus_free_buying_guide || 'bonus_free_buying_guide' }</span>
            <span class="modal-price-demo-container-info-content-detail-bonus-subtitle">${ htrLanguage.company.bonus_free_buying_guide_desc || 'bonus_free_buying_guide_desc' }</span>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-detail-default-properties-row">
          <div class="modal-price-demo-flex-row">
            <label class="modal-price-demo-container-info-content-top-products-checkbox-container">
              <input
                id="modal-price-demo-container-info-content-detail-accept-policy"
                class="modal-price-demo-container-info-content-top-products-checkbox"
                type="checkbox"
                name="top-product"
                value="consent"
                aria-label="Consent"
                checked="true">

              <span class="modal-price-demo-container-info-content-top-products-checkmark"></span>
            </label>
            <span>${ htrLanguage.company.privacy_policy_desc || 'privacy_policy_desc' } <a class="modal-price-demo-container-info-content-detail-policy-disclaimer" href="https://help.hoteltechreport.com/en/articles/1214349-user-privacy-policy" rel="noopener" target="_blank">${ (htrLanguage.layout.privacy_policy || 'privacy_policy').charAt(0).toUpperCase() + (htrLanguage.layout.privacy_policy || 'privacy_policy').toLowerCase().slice(1) }</a> </span>
          </div>
        </div>
      </div>
    `

    contentElement.empty()
    contentElement.append(htmlContent)

    integrateGoogleMaps('modal-price-demo-container-detail-company-hotel')
    preparePhoneInputToOnlyAcceptFormat()

    window.htr_showModalPriceDemo.countryDropdown = {
      searchCountry: '',
      timeoutSelect: null
    }
    $(window).off('keyup')
    $(window).on('keyup', (event) => {
      if ($('#modal-price-demo-container-info-content-detail-select-content').hasClass('modal-price-demo-select-open')) {
        if (event.keyCode === 27) {
          $('#modal-price-demo-container-info-content-detail-select-content').removeClass('modal-price-demo-select-open')
          $('#modal-price-demo-container-detail-arrow-flag').removeClass('modal-price-demo-select-arrow-rotate')
        } else {
          let optionSearch = { offset: () => ({ top: 0 }), notFind: true }
          if (event.keyCode === 8) {
            window.htr_showModalPriceDemo.countryDropdown.searchCountry = window.htr_showModalPriceDemo.countryDropdown.searchCountry.slice(0, -1)
          } else {
            window.htr_showModalPriceDemo.countryDropdown.searchCountry += String.fromCharCode(event.keyCode).toLowerCase()
          }
          $('.modal-price-demo-select-option').each(index => {
            if (optionSearch.notFind) {
              const optionDocument = $($('.modal-price-demo-select-option')[index])
              if (optionDocument.children('p') && optionDocument.children('p').text()) {
                if (optionDocument.children('p').text().toLowerCase().startsWith(window.htr_showModalPriceDemo.countryDropdown.searchCountry)) {
                  optionSearch = optionDocument
                }
              }
            }
          })
          const positionOption = optionSearch.offset().top
          const scrollCurrent = optionSearch.offset().top ? $('#modal-price-demo-container-info-content-detail-dropdown-00').scrollTop() : 0
          const positionContainerOptions = $('#modal-price-demo-container-info-content-detail-dropdown-00').offset().top
          $('#modal-price-demo-container-info-content-detail-dropdown-00').animate({ scrollTop: (positionOption + scrollCurrent) - positionContainerOptions }, 50)
          if (window.htr_showModalPriceDemo.countryDropdown.timeoutSelect) {
            clearTimeout(window.htr_showModalPriceDemo.countryDropdown.timeoutSelect)
            window.htr_showModalPriceDemo.countryDropdown.timeoutSelect = null
          }

          window.htr_showModalPriceDemo.countryDropdown.timeoutSelect = setTimeout(() => {
            window.htr_showModalPriceDemo.countryDropdown.searchCountry = ''
            window.htr_showModalPriceDemo.countryDropdown.timeoutSelect = null
          }, 700)
        }
      }
    })

    $('#modal-price-demo-select-wrapper').on('click', () => {
      if ($('#modal-price-demo-container-info-content-detail-select-content').hasClass('modal-price-demo-select-open')) {
        $('#modal-price-demo-container-info-content-detail-select-content').removeClass('modal-price-demo-select-open')
        $('#modal-price-demo-container-detail-arrow-flag').removeClass('modal-price-demo-select-arrow-rotate')
      } else {
        $('#modal-price-demo-container-info-content-detail-select-content').addClass('modal-price-demo-select-open')
        $('#modal-price-demo-container-detail-arrow-flag').addClass('modal-price-demo-select-arrow-rotate')
      }
    })

    $('#modal-price-demo-container-01').off('click')
    $('#modal-price-demo-container-01').on('click', (event) => {
      if (!$(event.target).parents('.modal-price-demo-select-wrapper').length) {
        $('#modal-price-demo-container-info-content-detail-select-content').removeClass('modal-price-demo-select-open')
        $('#modal-price-demo-container-detail-arrow-flag').removeClass('modal-price-demo-select-arrow-rotate')
      }
    })

    $('body').off('click', '#modal-price-demo-container-info-content-detail-dropdown-00 > div')
    $('body').on('click', '#modal-price-demo-container-info-content-detail-dropdown-00 > div', (event) => {
      let attributesParent = $(event.target)
      if (!$(event.target).attr('dial')) {
        attributesParent = $(event.target).parents('.modal-price-demo-select-option')
      }
      $('#modal-price-demo-container-detail-phone-country-code').attr('code', attributesParent.attr('code'))
      $('#modal-price-demo-container-detail-phone-country-code').attr('key', attributesParent.attr('dial'))
      $('#modal-price-demo-container-detail-phone-number').attr('placeholder', getExampleNumber((attributesParent.attr('code') || '').toUpperCase(), libPhoneNumberExamples).formatNational())
      $('#modal-price-demo-container-detail-phone-number').val(new AsYouType((attributesParent.attr('code') || '').toUpperCase()).input($('#modal-price-demo-container-detail-phone-number').val()))
      callOnce(validateInnerElements, 500 )
      $('#modal-price-demo-container-detail-flag-current').attr('src', `/images/flags_svg/${attributesParent.attr('code')}.svg`)
      if ($('.modal-price-demo-country-selected').length) {
        $('.modal-price-demo-country-selected').removeClass('modal-price-demo-country-selected')
      }
      attributesParent.addClass('modal-price-demo-country-selected')
    })

    validateAllDetailInputsFilled()

  }

  function printModalContentResendVerificationLink () {

    let spinnerElement = $('#modal-price-demo-spinner-02')
    let htmlContent = ''
    let contentElement = $('#modal-price-demo-container-info-content-00')
    let modalContentElement = $('#modal-price-demo-container-01')
    let messages = {
      'request_demo': (htrLanguage.company.demo || 'demo').toLowerCase(),
      'request_quote': (htrLanguage.company.price_quote || 'price_quote').toLowerCase()
    }
    let stringMessages

    if(titleString && typeof titleString == 'string'){
      stringMessages = messages.hasOwnProperty(titleString) ? messages[titleString] : ''
    }

    htmlContent = `
      <div class="modal-price-demo-container-info-content-resend-link-default-container">
        <div class="modal-price-demo-container-info-content-resend-link-default-row modal-price-demo-flex-row">
          <div class="modal-price-demo-container-info-content-resend-link-success modal-price-demo-flex-row">
            <img src="/images/email-sent-green.svg" height="16" width="28" alt="${ htrLanguage.company.email_sent || 'email_sent' }">
            <span>${ htrLanguage.company.success_verification_email || 'success_verification_email' }</span>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-resend-link-default-row">
          <span>${ htrLanguage.company.verification_email_resend_1 || 'verification_email_resend_1' } <span id="modal-price-demo-container-info-content-resend-link-email-text">${ window.htr_showModalPriceDemo.selectedData.email }</span> ${ htrLanguage.company.verification_email_resend_2 || 'verification_email_resend_2' } ${ stringMessages } ${ htrLanguage.company.verification_email_resend_3 || 'verification_email_resend_3' }</span>
        </div>
        <div class="modal-price-demo-container-info-content-resend-link-default-row">
          <div class="modal-price-demo-container-info-content-resend-link-input-button-holder">
            <input
              id="modal-price-demo-container-info-content-resend-link-email-00"
              name="email"
              class="modal-price-demo-container-info-content-resend-link-email-input"
              value="${ window.htr_showModalPriceDemo.selectedData.email }"
              maxlenght="50"
              type="email">
            <button
              class="modal-price-demo-container-info-content-resend-link-email-button">
              ${ htrLanguage.company.resend || 'resend' }
            </button>
          </div>
        </div>
        <div class="modal-price-demo-container-info-content-resend-link-default-row">
          <div class="modal-price-demo-container-info-content-resend-link-quiz-holder modal-price-demo-flex-column">
            <span>${ htrLanguage.company.info_resend_1 || 'info_resend_1' } ${ translate(window.htr_showModalPriceDemo.productData.category, 'name') } ${ htrLanguage.company.info_resend_2 || 'info_resend_2' }</span>
            <button
              class="modal-price-demo-container-info-content-resend-link-quiz-button">
              ${ (htrLanguage.home.take_the_quiz || 'take_the_quiz').charAt(0).toUpperCase() + (htrLanguage.home.take_the_quiz || 'take_the_quiz').toLowerCase().slice(1) }
            </button>
          </div>
        </div>
      </div>

      `


    modalContentElement.addClass('modal-price-demo-height-auto')
    contentElement.addClass('modal-price-demo-height-auto')
    spinnerElement.hide()
    contentElement.show()

    contentElement.append(htmlContent)

    let textEmailSpanItem = $('#modal-price-demo-container-info-content-resend-link-email-text')
    let inputEmailItem = $('#modal-price-demo-container-info-content-resend-link-email-00')
    let sendButtonItem = $('.modal-price-demo-container-info-content-resend-link-email-button')

    sendButtonItem.on('click', () => {
      resendEmailToUser()
    })

    inputEmailItem.on('keyup', () => {
      if (textEmailSpanItem.length && inputEmailItem.length) textEmailSpanItem.text(inputEmailItem.val())
    })

    $('.modal-price-demo-container-info-content-resend-link-quiz-button').on('click', (event) => {
        clearingWindowDom(true)
        window.location.href = '/get-advice'
    })
  }

  /* FOOTER PRINT FUNTIONS*/
  function printModalFooter (productInfo, companyData, customizedMessage, readyToNext = false) {
    let htmlContent
    let htmlNextButton = ''
    let contentElement = $('#modal-price-demo-container-info-footer-00')
    if(!contentElement.length) {
      return 0
    }

    const nextText = (htrLanguage.news.next || 'next').toLowerCase()
    if(readyToNext) {
      htmlNextButton = `
      <button class="modal-price-demo-container-info-footer-next-button modal-price-demo-vertical-align">
        ${nextText.charAt(0).toUpperCase() + nextText.slice(1)}
      </button>`
    } else {
      htmlNextButton = `
      <button class="modal-price-demo-container-info-footer-next-button modal-price-demo-vertical-align" disabled="disabled">
        ${nextText.charAt(0).toUpperCase() + nextText.slice(1)}
      </button>`
    }


    htmlContent = `
    <div class="modal-price-demo-container-info-footer-elements">
      <div class="modal-price-demo-flex-column modal-price-demo-margin-top-15">
        <span class="modal-price-demo-container-info-footer-msg">${ htrLanguage.company.step_1_of_3 || 'step_1_of_3' }</span>
        <div class="modal-price-demo-container-info-footer-overview-mark-line">
          <div style="width: 33%"></div>
        </div>
      </div>
      ${ htmlNextButton }
    </div>`

    contentElement.append(htmlContent)

    $('#modal-price-demo-container-01').off('click')
    $('#modal-price-demo-container-01').on('click', (event) => {
      if($(event.target).attr('id') === 'modal-price-demo-container-info-content-PMS-section-search-compatible-pms' ||
        $(event.target).parent().attr('id') === 'modal-price-demo-container-info-content-PMS-section-search-dropdown-00') {
        return 0
      } else {

        if(!$('#modal-price-demo-container-info-content-PMS-section-search-dropdown-00').is(':hidden')) {
          $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').val('')
          $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').removeAttr('key')
          $('#modal-price-demo-container-info-content-PMS-section-search-dropdown-00').hide()
        }
        return 0
      }

    })

    $('body').off('click', '#modal-price-demo-container-info-content-PMS-section-search-dropdown-00 > li')
    $('body').on('click', '#modal-price-demo-container-info-content-PMS-section-search-dropdown-00 > li', (event) => {

      if($(event.target).parent().attr('key') === 'other') {
        $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').val($(event.target).parent().attr('value'))
        $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').attr('key', 'other')
      } else {
        $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').val($(event.target).text())
        $('#modal-price-demo-container-info-content-PMS-section-search-compatible-pms').attr('key', $(event.target).attr('key'))
      }

      $('#modal-price-demo-container-info-content-PMS-section-search-dropdown-00').hide()
    })

    $('body').off('click', '.modal-price-demo-container-info-footer-next-button')
    $('body').on('click', '.modal-price-demo-container-info-footer-next-button', (event) => {
      if (window.htr_showModalPriceDemo.step >= 0) {
        switch (window.htr_showModalPriceDemo.step) {
          case 0:
            $('#modal-price-demo-container-01').off('click')
            printModalTopMatches($('#modal-price-demo-container-info-content-select-item-region').children('option:selected').val())
            break;
          case 1:
            printModalDetails()
            break;
          case 2:
            verifyToPrintResendVerificationLink()
          default:
            return 0
        }
      } else {
        return 0
      }
    })

    resizeHeigthOnMobile()
  }

  function printModalFooterTopMatches () {

    let footerSpanElement = $('.modal-price-demo-container-info-footer-msg')
    let stringContent = htrLanguage.company.step_2_of_3 || 'step_2_of_3'
    let buttonElement = $('.modal-price-demo-container-info-footer-next-button')
    $('.modal-price-demo-container-info-footer-overview-mark-line > div').attr('style', 'width: 66%')

    buttonElement.text(htrLanguage.company.final_step || 'final_step')
    footerSpanElement.text(stringContent)
    resizeHeigthOnMobile()
  }

  function printModalFooterDetails () {

    let messages = {
      'request_demo': htrLanguage.company.demo || 'demo',
      'request_quote': htrLanguage.company.quote || 'quote'
    }
    let stringMessages = ''
    let canGoNextStep = false

    if(titleString && typeof titleString == 'string'){
      stringMessages = messages.hasOwnProperty(titleString) ? messages[titleString] : ''
    }

    let footerSpanElement = $('.modal-price-demo-container-info-footer-msg')
    let buttonElement = $('.modal-price-demo-container-info-footer-next-button')
    let stringContent = htrLanguage.company.step_3_of_3 || 'step_3_of_3'
    let stringButton = (htrLanguage.company.get || 'get') +' '+ stringMessages

    $('.modal-price-demo-container-info-footer-overview-mark-line > div').attr('style', 'width: 100%')
    buttonElement.text(stringButton)
    footerSpanElement.text(stringContent)
    canGoNextStep = validateInnerElements()

    if(canGoNextStep.length === 0) {
      $('.modal-price-demo-container-info-footer-next-button').removeAttr('disabled')
    } else {
      $('.modal-price-demo-container-info-footer-next-button').attr('disabled', 'disabled')
    }

    resizeHeigthOnMobile()

  }

  function printModalFooterResendVerificationLink () {

    let footerElement = $('#modal-price-demo-container-info-footer-00')
    footerElement.empty()
    footerElement.removeClass('modal-price-demo-container-info-footer')
  }

  /* AUXILIAR FUNCTIONS */
  /*
   * Validate if an object has a key and value
   */
  function validateIfWindowHasElement(object, key, value) {
    if(object && object.hasOwnProperty(key) && object[key] == value) {
      return true
    }
    return false
  }

  function translate (entry, field) {
    let translation = entry[field]
    if (entry.translations && entry.translations.length > 0) {
      if (window.Laravel.languageLocale) {
        const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === window.Laravel.languageLocale.id)
        if (searchTranslation) {
          translation = searchTranslation[field]
        }
      }
    }
    return translation
  }

  function printModalContentSelectInput(arrayItem, keyToCheck) {

    if(!Array.isArray(arrayItem)) return ''

    let storedData = window.htr_showModalPriceDemo.selectedData ? window.htr_showModalPriceDemo.selectedData : null
    let ls_hotel_type = null
    let ls_region = null
    let ls_property_size = null
    if (!storedData) {

      if (window.localStorage.getItem('hotel_info')) {
        ({
          ls_hotel_type,
          ls_region,
          ls_property_size
        } = JSON.parse(window.localStorage.getItem('hotel_info')))
        storedData = {
          hotel_type: ls_hotel_type,
          region: ls_region,
          property_size: ls_property_size
        }
      }
    }

    let htmlInputObject = {
      'content': '',
      'optionSelectedfound': false
    }

    let htmlDefaultOption = `
      <option value="null" disabled hidden selected></option>`

    arrayItem.forEach((item) => {
      if( storedData && validateIfWindowHasElement(item, 'value', storedData[keyToCheck]) ){
        htmlInputObject.optionSeletedfound = true
        htmlInputObject.content += `
            <option value="${item.value}" selected> ${ item.label }</option>`
      } else {
        htmlInputObject.content += `
            <option value="${item.value}"> ${ item.label }</option>`
      }
    })

    if (!htmlInputObject.optionSeletedfound) {
      htmlInputObject.content = htmlDefaultOption + htmlInputObject.content
    }

    return htmlInputObject
  }

  function printModalTopMatchesProducts(productArray) {
    let htmlContent = ''
    let htmlRanks = ''
    let isMobile = false
    let requestMessage = titleString

    if (titleString === 'request_quote' && htrLanguage.company.request_quote) {
      requestMessage = htrLanguage.company.request_quote
    }
    if (titleString === 'request_demo' && htrLanguage.company.request_demo) {
      requestMessage = htrLanguage.company.request_demo
    }

    if(window.matchMedia('(max-width: 767px)').matches) {
      isMobile = true
    }

    if(Array.isArray(productArray) && productArray.length > 0) {
      productArray.forEach((product) => {

        let likehoodRecommend = 0
        if (product.recommend_rank) {
          likehoodRecommend = Math.round(product.recommend_rank)
        }
        htmlRanks = ''

        for (let i=1; i<6; i++) {
          htmlRanks += `
                <div class="star">`
          if (likehoodRecommend/20 > i) {
            htmlRanks += `
                  <img src="/images/star-full-blue.svg" height="19" width="19" alt="${ htrLanguage.company.full_star_blue || 'full_star_blue' }">`
          } else if (likehoodRecommend/20 >= (i-1) + 0.2 && likehoodRecommend/20 < i ) {
            htmlRanks += `
                  <img src="/images/star-half-blue.svg" height="19" width="19" alt="${ htrLanguage.company.half_star_blue || 'half_star_blue' }">`
          } else {
            htmlRanks += `
                  <img src="/images/star-empty-blue.svg" height="19" width="19" alt="${ htrLanguage.company.empty_star_blue || 'empty_star_blue' }">`
          }
          htmlRanks += `
                </div>
          `
        }

        htmlContent += `
        <div class="modal-price-demo-flex-row modal-price-demo-container-info-content-top-products-row">
          <div class="modal-price-demo-flex-row">
            <a
              href="${ product.url }"
              target="_blank"
              rel="noopener"
              class="modal-price-demo-vertical-align">
              <img src="${ product.company.logo ? product.company.logo : '/images/company-default-logo.png' }" height="48" width="48" alt="${ htrLanguage.news.company_logo || 'company_logo' }">
            </a>
            <div class="modal-price-demo-flex-column modal-price-demo-container-info-content-top-products-company-container">
              <a
                href="${ product.url }"
                target="_blank"
                rel="noopener">
                <span class="modal-price-demo-container-info-content-top-products-company-title">
                  ${ product.name }
                </span>
              </a>
              <div class="profile-box-mark-stars">
                ${ htmlRanks }
                <a class="modal-price-demo-container-info-content-top-products-company-reviews" href="${ product.url + '#section-reviews'}" target="_blank" rel="noopener">
                  ${ product.reviews_count } ${ htrLanguage.company.verified_reviews || 'verified_reviews' }
                </a>
              </div>
              <p class="modal-price-demo-container-info-content-top-products-company-recommended">
                <i class="icon-like"></i> ${ product.recommend_rank }% ${ htrLanguage.company.recommended || 'recommended' }${ (window.htr_showModalPriceDemo.HotelTypeDescription && !isMobile) ? ` ${ htrLanguage.company.by || 'by' } ` + window.htr_showModalPriceDemo.HotelTypeDescription : '' }
              </p>
            </div>
          </div>
          <div class="modal-price-demo-container-info-content-top-products-checkbox-container-div">
            <p class="modal-price-demo-container-info-content-top-products-checkbox-type-message">
              ${ requestMessage }
            </p>
            <label class="modal-price-demo-container-info-content-yes-no-section-switch">
              <input
                class="modal-price-demo-container-info-content-top-products-checkbox"
                type="checkbox"
                checked="true"
                name="top-product"
                value="${ product.id }"
                aria-label="Product id">
              <span class="modal-price-demo-container-info-content-yes-no-section-switch-slider modal-price-demo-container-info-content-yes-no-section-switch-round" />
              <p class="modal-price-demo-container-info-content-yes-text"> ${ htrLanguage.company.yes || 'Yes' } </p>
              <p class="modal-price-demo-container-info-content-no-text"> ${ htrLanguage.company.no || 'No' } </p>
            </label>
          </div>
        </div>`
      })
      return htmlContent
    } else {
      return window.htr_showModalPriceDemo_html.emptyProductList
    }
  }

  function sortAlphabet (arrayToOrder) {
    if(Array.isArray(arrayToOrder)) {
      return arrayToOrder.sort((a, b) => {
        if (a.value === 'other') return 1
        if (b.value === 'other') return -1

        return a.label.localeCompare(b.label)
      })
    } else {
      return []
    }
  }

  function printDropdownList (arrayList) {

    if(!Array.isArray(arrayList) || arrayList.length === 0) {
      return ''
    }

    let pmsId = null
    let pmsValue = null
    if (window.htr_showModalPriceDemo.selectedData != null && (window.htr_showModalPriceDemo.hasOwnProperty('selectedData') && window.htr_showModalPriceDemo.selectedData.hasOwnProperty('pms_id'))) {

      if (window.htr_showModalPriceDemo.selectedData.pms_id === 'other' && window.htr_showModalPriceDemo.selectedData.pms_other) {
        pmsId = 'other'
        pmsValue = window.htr_showModalPriceDemo.selectedData.pms_other
      } else {
        pmsId = Number(window.htr_showModalPriceDemo.selectedData.pms_id)
      }
    } else if (window.localStorage.getItem('pms_id')) {
      let ls_pms_id = null;
      ({
        ls_pms_id
      } = JSON.parse(window.localStorage.getItem('pms_id')))
      pmsId = Number(ls_pms_id)
    }

    let htmlContentObject = {
      'content': '',
      'key': pmsId,
      'value': pmsValue
    }

    arrayList.forEach((pmsProduct) => {
      if (htmlContentObject.key !== null && Number(pmsProduct.id) === htmlContentObject.key) {
        htmlContentObject.key = pmsProduct.id
        htmlContentObject.value = pmsProduct.name
      }
      htmlContentObject.content += `
              <li key="${ pmsProduct.id }">${ pmsProduct.name }</li>`
    })

    return htmlContentObject
  }

  function filterArrayList(arrayList, stringToFind) {
    if (!Array.isArray(arrayList) || arrayList.length === 0 || (typeof stringToFind !== 'string' && stringToFind !== null)) {
      return []
    }
    if (stringToFind == null) {
      stringToFind = ''
    }

    // $& means the whole matched string
    let scapeSpecialChars = stringToFind.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let regExp = new RegExp(`${scapeSpecialChars}`, 'gi')
    let filteredList

    if (stringToFind == '') {
      filteredList = arrayList
    } else {
      filteredList = arrayList.filter( element => element.name.search(regExp) >= 0)
    }

    return filteredList
  }

  function printModalTopMatches(region) {
    //printModalHeaderTopMatches(region)
    printModalContentTopMatches()
    printModalFooterTopMatches()
  }

  function printModalDetails() {
    window.htr_showModalPriceDemo.step = 2
    printModalHeaderDetails()
    printModalContentDetails()
    printModalFooterDetails()
    $('body').off('click', '.modal-price-demo-container-info-content-top-products-checkbox')
  }

  function verifyToPrintResendVerificationLink() {
    if(!validateInputDetailsBeforeSend()) return 0
    updateWindowForm()
  }

  function printResendVerificationLink() {

    let contentElement = $('#modal-price-demo-container-info-content-00')
    window.htr_showModalPriceDemo.step = 3

    contentElement.empty()
    printModalContentResendVerificationLink()
    printModalFooterResendVerificationLink()

  }

  function clearingWindowDom (finalStep = false) {
    window.htr_showModalPriceDemo.productData = null
    window.htr_showModalPriceDemo.companyData = null
    window.htr_showModalPriceDemo.productPmsData = null
    window.htr_showModalPriceDemo.step = null
    window.htr_showModalPriceDemo.requestType = null
    window.htr_showModalPriceDemo.selectedData = null
    window.htr_showModalPriceDemo.userVerified = null
    window.htr_showModalPriceDemo.HotelTypeDescription = null
    window.htr_showModalPriceDemo.selectedTopProducts = null

    if (finalStep) {
      window.htr_showModalPriceDemo.leads = null
    }
  }

  function printDialCodes (codeSelected) {
    let htmlContent = ''

    Object.keys(dialCodes).forEach((dialcode) => {
      htmlContent += `
                <div dial="${ dialCodes[dialcode].dial_code }" code="${ dialCodes[dialcode].code.toLowerCase() }" class="modal-price-demo-select-option ${ dialCodes[dialcode].code === codeSelected ? 'modal-price-demo-country-selected' : '' }">
                  <img class="modal-price-demo-select-flag marginRight5px" src="/images/flags_svg/${dialCodes[dialcode].code.toLowerCase()}.svg" />
                  <p>${ dialCodes[dialcode].name } <span class="modal-price-demo-dial-code">${ dialCodes[dialcode].dial_code }</span></p>
                </div>`
    })

    return htmlContent
  }

  function validateBusinessEmail (stringToValidate) {
    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const BUSINESS_EMAIL_REGEX = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))+@(?!qq.com)(?!yahoo.com)(?!gmail.com)(?!ymail.com)(?!hotmail.com)(?!yahoo.co.uk)(?!yahoo.fr)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)([\w-]+\.)+[\w-]{2,6})?$/i
    if(typeof stringToValidate !== 'string') {
      return false
    }

    return EMAIL_REGEX.test(stringToValidate)
  }

  function validateGenericEmail (email) {
    const genericEmails =
      ['commercial@', 'hotel@', 'accounting@', 'vacation@', 'bookings@', 'booking@', 'fom@', 'FOM@', 'management@', 'reception@', 'Fom@', 'Stay@', 'stay@', 'reservas@', 'manager@', 'contact@', 'it@', 'operations@', 'reservation@', 'fdmanager@', 'revenue@', 'hello@', 'Hello@', 'marketing@', 'mail@', 'sales@', 'reservations@', 'Reservations@', 'admin@', 'office@', 'gm@', 'Gm@', 'welcome@', 'info@', 'Info@']
    let isGeneric = false
    if (email && email.length > 0) {
      genericEmails.every(item => {
        if (email.includes(item)) {
          isGeneric = true
          return false
        }
        return true
      })
    }
    return isGeneric
  }

  function validatePersonalEmail (email) {
    const BUSINESS_EMAIL_REGEX = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))+@(?!qq.com)(?!yahoo.com)(?!gmail.com)(?!ymail.com)(?!hotmail.com)(?!yahoo.co.uk)(?!yahoo.fr)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)([\w-]+\.)+[\w-]{2,6})?$/i
    return !!(validateBusinessEmail(email) && email.length > 0 && !BUSINESS_EMAIL_REGEX.test(email))
  }

  function integrateGoogleMaps(elementId) {
    if(!$('#'+elementId).length) {
      return 0
    }

    const autocomplete = new google.maps.places.Autocomplete(document.getElementById(elementId))
    autocomplete.addListener('place_changed', () => {
      setAddressToWindowSelectData(autocomplete.getPlace())
    })

  }

  function setAddressToWindowSelectData (address) {
    const addressMappings = {
      locality: 'city',
      country: 'country',
      route: 'region',
      administrative_area_level_1: 'state',
      administrative_area_level_3: 'city',
      sublocality_level_2: 'city'
    }

    const location = {
      address: address.formatted_address,
      city: null,
      country: null,
      country_iso_code: null,
      state: null,
      region: null,
      phone: address.international_phone_number || address.formatted_phone_number || null,
      website: address.website,
      name: address.name || null
    }

    address.address_components.forEach( item => {
      if (addressMappings.hasOwnProperty(item.types[0])) {
        if (item.types[0] === addressMappings.country){
          location['country_iso_code'] = item.short_name || null
        }
        location[addressMappings[item.types[0]]] = item.long_name || null
      }
    })

    if (window.htr_showModalPriceDemo.selectedData) {

      window.htr_showModalPriceDemo.selectedData.company = location.name || null
      window.htr_showModalPriceDemo.selectedData.city = location.city || null
      window.htr_showModalPriceDemo.selectedData.country = location.country || null
      window.htr_showModalPriceDemo.selectedData.country_iso_code = location.country_iso_code || null
      window.htr_showModalPriceDemo.selectedData.state = location.state || null
      window.htr_showModalPriceDemo.selectedData.company_phone = location.phone || null
      window.htr_showModalPriceDemo.selectedData.website = location.website || null
      window.htr_showModalPriceDemo.selectedData.company_website = location.website || null
    }
    window.storeVisitorFields(window.htr_showModalPriceDemo.selectedData)
  }

  function validateAllDetailInputsFilled() {
    $('.modal-price-demo-container-info-content-detail-default-properties-input').on('input focusout', (e) => callOnce(validateInnerElements, 500 ))
    $('#modal-price-demo-container-info-content-detail-accept-policy').on('change', (e) => callOnce(validateInnerElements, 500 ))
  }

  function validateInnerElements (event = null) {
    let canProceedToSend = true
    let inputElements = $('.modal-price-demo-container-info-content-detail-default-properties-input')
    let inputProblems = []
    inputElements.each((index) => {
      if ($(inputElements[index]).attr('id') == 'modal-price-demo-container-detail-work-email') {
        $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-success-large')
        $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-error-large')
        $(inputElements[index]).parent().next().addClass('hidden')
        $('#tip-warning-for-email').empty()
        $('#tip-warning-for-email').hide()
        $('#tip-warning-for-validate-url').hide()
        const email = $(inputElements[index]).val()
        const genericEmailText = '<span>💡TIP: Use your email instead of a generic email to get instantly verified</span>'
        const personalEmailText = `<span>🚨 ${ htrLanguage.company.personal_email || "exit_warning_1" }</span>`

        if (validateGenericEmail(email)) {
          $('#tip-warning-for-email').html(genericEmailText)
          $('#tip-warning-for-email').show();
          $('#tip-warning-for-validate-url').hide()
          if($('#tip-warning-for-validate-url').css('display') == 'none') {
            $('#modal-price-demo-container-detail-confirmation-url').val('')
          }
        }

        if (validatePersonalEmail(email)) {
          $('#tip-warning-for-email').html(personalEmailText)
          $('#tip-warning-for-email').show();
          $('#valide-site-input-container').show()
          if(typeof email === 'string' && email.toLowerCase().endsWith('@gmail.com')) {
            $('#tip-warning-for-validate-url').show()
          } else {
            $('#tip-warning-for-validate-url').hide()
            if($('#tip-warning-for-validate-url').css('display') == 'none') {
              $('#modal-price-demo-container-detail-confirmation-url').val('')
            }
          }
        }

        if ($(inputElements[index]).val().length > 0) {
          if(!validateBusinessEmail($(inputElements[index]).val())) {
            $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-default-error-large')
            $(inputElements[index]).parent().next().removeClass('hidden')
            inputProblems.push(inputElements[index])
            canProceedToSend = false
          } else {
            if (!window.htr_showModalPriceDemo.leads.email
              || (window.htr_showModalPriceDemo.leads.email.email !== $(inputElements[index]).val())) {
                window.htr_showModalPriceDemo.leads.email = undefined
                $(inputElements[index]).parents('.modal-price-demo-container-info-content-detail-default-properties-container').find('.modal-price-demo-container-verification-email').addClass('hidden')

                canProceedToSend = false
                inputProblems.push(inputElements[index])
                verifyEmailForLead($(inputElements[index]).val())
            }


            if(window.htr_showModalPriceDemo.leads.email && window.htr_showModalPriceDemo.leads.email.isValid) {
              if (typeof email === 'string' && email.toLowerCase().endsWith('@gmail.com')) {
                $(inputElements[index]).parents('.modal-price-demo-container-info-content-detail-default-properties-container').find('.modal-price-demo-container-verification-email').removeClass('hidden')
                $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-success-large');
              } else {
                $(inputElements[index]).parents('.modal-price-demo-container-info-content-detail-default-properties-container').find('.modal-price-demo-container-verification-email').addClass('hidden')
                $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-default-success-large');

              }
            }

            if(window.htr_showModalPriceDemo.leads.email && !window.htr_showModalPriceDemo.leads.email.isValid) {
              $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-default-error-large')
              $(inputElements[index]).parent().next().removeClass('hidden')
              inputProblems.push(inputElements[index])
              canProceedToSend = false
            }
          }
          return
        } else {
            $('#tip-warning-for-validate-url').hide()
            inputProblems.push(inputElements[index])
            canProceedToSend = false
        }
      } else if($(inputElements[index]).attr('id') == 'modal-price-demo-container-detail-phone-number') {
        $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-phone-number-default-success-medium')
        $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-phone-number-default-error-medium')
        $(inputElements[index]).parent().parent().next().addClass('hidden')

        if ($(inputElements[index]).val().length > 0) {
          let stringPhone = $(inputElements[index]).val()
          let phoneNumber = {
            isPossible: () => false
          }
          if (stringPhone.length > 2) {
            phoneNumber = parsePhoneNumber(stringPhone, ($('#modal-price-demo-container-detail-phone-country-code') ? $('#modal-price-demo-container-detail-phone-country-code').attr('code') : '').toUpperCase()) || phoneNumber
          }

          if(phoneNumber.isPossible()) {
            if ((phoneNumber.country === 'US' || phoneNumber.country === 'CA' )) {
              if (!window.htr_showModalPriceDemo.leads.phone
                || (window.htr_showModalPriceDemo.leads.phone.phone !== $(inputElements[index]).val())) {
                window.htr_showModalPriceDemo.leads.phone = undefined
                canProceedToSend = false
                verifyPhoneForLead($(inputElements[index]).val())
              }

              if(window.htr_showModalPriceDemo.leads.phone && window.htr_showModalPriceDemo.leads.phone.isValid) {
                $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-phone-number-default-success-medium')
                $(inputElements[index]).parent().parent().next().addClass('hidden')
              }

              if(window.htr_showModalPriceDemo.leads.phone && !window.htr_showModalPriceDemo.leads.phone.isValid) {
                $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-phone-number-default-error-medium')
                $(inputElements[index]).parent().parent().next().removeClass('hidden')
                inputProblems.push(inputElements[index])
                canProceedToSend = false
              }
            } else {
              $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-phone-number-default-success-medium')
              $(inputElements[index]).parent().parent().next().addClass('hidden')
            }
          } else {
            $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-phone-number-default-error-medium')
            $(inputElements[index]).parent().parent().next().removeClass('hidden')
            inputProblems.push(inputElements[index])
            canProceedToSend = false
          }
        }
        return;
      } else if($(inputElements[index]).val() == '' && $(inputElements[index]).attr('id') !== 'modal-price-demo-container-detail-phone-country-code' && $(inputElements[index]).attr('id') !== 'modal-price-demo-container-detail-confirmation-url') {
        $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-success-large')
        $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-error-large')
        $(inputElements[index]).parent().next().addClass('hidden')
        inputProblems.push(inputElements[index])
        canProceedToSend = false
      } else if ($(inputElements[index]).attr('id') !== 'modal-price-demo-container-detail-phone-country-code' && $(inputElements[index]).attr('id') !== 'modal-price-demo-container-detail-confirmation-url') {
        $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-default-success-large')
        $(inputElements[index]).parent().next().addClass('hidden')
      } else if($(inputElements[index]).attr('id') == 'modal-price-demo-container-detail-confirmation-url') {
        let validateUrl = urlString => {
          var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
          '(\\#[-a-z\\d_]*)?$','i') // validate fragment locator
        return !!urlPattern.test(urlString)
        }
        if($(inputElements[index]).val().length === 0) {
            $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-success-large modal-price-demo-container-info-content-confirmation-url');
            $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-error-large');
            if(window.htr_showModalPriceDemo.leads.email && window.htr_showModalPriceDemo.leads.email.isValid && $('#tip-warning-for-validate-url').is(':visible')) {
              inputProblems.push(inputElements[index]);
              canProceedToSend = false;
            }
        } else {
          if(validateUrl($('#modal-price-demo-container-detail-confirmation-url').val()) === false) {
            $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-success-large modal-price-demo-container-info-content-confirmation-url');
            $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-default-error-large');
            if(window.htr_showModalPriceDemo.leads.email && window.htr_showModalPriceDemo.leads.email.isValid && $('#tip-warning-for-validate-url').is(':visible')) {
              inputProblems.push(inputElements[index]);
              canProceedToSend = false;
            }
          } else {
            $(inputElements[index]).parent().removeClass('modal-price-demo-container-info-content-detail-default-error-large');
            $(inputElements[index]).parent().addClass('modal-price-demo-container-info-content-detail-default-success-large modal-price-demo-container-info-content-confirmation-url');
          }
        }

      }
      if($('#tip-warning-for-validate-url').css('display') == 'none') {
        $('#modal-price-demo-container-detail-confirmation-url').val()
      }
    })

    //Proceed to store to cache before user get out
    window.htr_showModalPriceDemo.selectedData.first_name = $('#modal-price-demo-container-detail-first-name').length ? $('#modal-price-demo-container-detail-first-name').val() : null
    window.htr_showModalPriceDemo.selectedData.last_name = $('#modal-price-demo-container-detail-last-name').length ? $('#modal-price-demo-container-detail-last-name').val() : null
    window.htr_showModalPriceDemo.selectedData.full_name =
      (window.htr_showModalPriceDemo.selectedData.first_name ? window.htr_showModalPriceDemo.selectedData.first_name + ' ' : '')
      + (window.htr_showModalPriceDemo.selectedData.last_name ? window.htr_showModalPriceDemo.selectedData.last_name : '')
    window.htr_showModalPriceDemo.selectedData.title = $('#modal-price-demo-container-detail-job-title').length ? $('#modal-price-demo-container-detail-job-title').val() : null
    window.htr_showModalPriceDemo.selectedData.email = $('#modal-price-demo-container-detail-work-email').length ? $('#modal-price-demo-container-detail-work-email').val() : null
    window.htr_showModalPriceDemo.selectedData.confirmation_url = $('#modal-price-demo-container-detail-confirmation-url').length ? $('#modal-price-demo-container-detail-confirmation-url').val() : null
    if($('#modal-price-demo-container-detail-confirmation-url').length) {
      let confirmation = $('#modal-price-demo-container-detail-confirmation-url').val()
      window.htr_showModalPriceDemo.selectedData.confirmation_url = validateHttpFormat(confirmation)
    } else {
      window.htr_showModalPriceDemo.selectedData.confirmation_url = null;
    }
    if($('#modal-price-demo-container-detail-phone-country-code').length > 0 && $('#modal-price-demo-container-detail-phone-number').length > 0) {
      let phoneString = $('#modal-price-demo-container-detail-phone-country-code').attr('key') ? $('#modal-price-demo-container-detail-phone-country-code').attr('key') : ''
      phoneString += ' ' + $('#modal-price-demo-container-detail-phone-number').val()
      window.htr_showModalPriceDemo.selectedData.phone = phoneString
      window.htr_showModalPriceDemo.selectedData.id_country = $('#modal-price-demo-container-detail-phone-country-code').attr('code') ? $('#modal-price-demo-container-detail-phone-country-code').attr('code') : 'US'
    }

    window.storeVisitorFields(window.htr_showModalPriceDemo.selectedData)

    if(!$('#modal-price-demo-container-info-content-detail-accept-policy').prop('checked')) {
        canProceedToSend = false
    }

    if(canProceedToSend) {
      $('.modal-price-demo-container-info-footer-next-button').removeAttr('disabled')
    } else {
      $('.modal-price-demo-container-info-footer-next-button').attr('disabled', 'disabled')
    }
    return inputProblems
  }

  function validateInputDetailsBeforeSend () {
    let problemsArray = validateInnerElements()

    if(problemsArray.length > 0) {
      problemsArray.forEach((element) => {
        if ($(element).hasClass('modal-price-demo-container-info-content-detail-small-input')) {
          $(element).parent().addClass('modal-price-demo-container-info-content-detail-default-error-small')
        } else if ($(element).hasClass('modal-price-demo-container-info-content-detail-medium-input')) {
          $(element).parent().addClass('modal-price-demo-container-info-content-detail-default-error-medium')
        } else {
          $(element).parent().addClass('modal-price-demo-container-info-content-detail-default-error-large')
        }
        $('.modal-price-demo-container-info-footer-next-button modal-price-demo-vertical-align').attr('disable', 'disable')
      })
      return false
    }

    return true
  }

  function updateWindowForm() {


    window.htr_showModalPriceDemo.selectedData.first_name = $('#modal-price-demo-container-detail-first-name').length ? $('#modal-price-demo-container-detail-first-name').val() : null
    window.htr_showModalPriceDemo.selectedData.last_name = $('#modal-price-demo-container-detail-last-name').length ? $('#modal-price-demo-container-detail-last-name').val() : null

    window.htr_showModalPriceDemo.selectedData.full_name =
      (window.htr_showModalPriceDemo.selectedData.first_name ? window.htr_showModalPriceDemo.selectedData.first_name + ' ' : '')
      + (window.htr_showModalPriceDemo.selectedData.last_name ? window.htr_showModalPriceDemo.selectedData.last_name : '')
    window.htr_showModalPriceDemo.selectedData.title = $('#modal-price-demo-container-detail-job-title').length ? $('#modal-price-demo-container-detail-job-title').val() : null

    if(window.htr_showModalPriceDemo.selectedData.company && $('#modal-price-demo-container-detail-company-hotel').length > 0) {
      if($('#modal-price-demo-container-detail-company-hotel').val().search(window.htr_showModalPriceDemo.selectedData.company) < 0) {
        window.htr_showModalPriceDemo.selectedData.city = null
        window.htr_showModalPriceDemo.selectedData.state = null
        window.htr_showModalPriceDemo.selectedData.company_phone = null
        window.htr_showModalPriceDemo.selectedData.country = null
        window.htr_showModalPriceDemo.selectedData.country_iso_code = null
        window.htr_showModalPriceDemo.selectedData.company = $('#modal-price-demo-container-detail-company-hotel').val()
      }
    } else if(window.htr_showModalPriceDemo.selectedData.company === null) {
      window.htr_showModalPriceDemo.selectedData.company = $('#modal-price-demo-container-detail-company-hotel').val()
    }

    window.htr_showModalPriceDemo.selectedData.email = $('#modal-price-demo-container-detail-work-email').length ? $('#modal-price-demo-container-detail-work-email').val() : null

    if($('#modal-price-demo-container-detail-confirmation-url').length) {
      let confirmation = $('#modal-price-demo-container-detail-confirmation-url').val()
      window.htr_showModalPriceDemo.selectedData.confirmation_url = validateHttpFormat(confirmation)
    }

    $('#modal-price-demo-container-detail-phone-number').val(new AsYouType(($('#modal-price-demo-container-detail-phone-country-code') ? $('#modal-price-demo-container-detail-phone-country-code').attr('code') : '').toUpperCase()).input($('#modal-price-demo-container-detail-phone-number').val()))

    if($('#modal-price-demo-container-detail-phone-country-code').length > 0 && $('#modal-price-demo-container-detail-phone-number').length > 0) {
      let phoneString = $('#modal-price-demo-container-detail-phone-country-code').attr('key') ? $('#modal-price-demo-container-detail-phone-country-code').attr('key') : ''
      phoneString += ' ' + $('#modal-price-demo-container-detail-phone-number').val()
      window.htr_showModalPriceDemo.selectedData.phone = phoneString
      window.htr_showModalPriceDemo.selectedData.id_country = $('#modal-price-demo-container-detail-phone-country-code').attr('code') ? $('#modal-price-demo-container-detail-phone-country-code').attr('code') : 'US'
    }
    window.htr_showModalPriceDemo.selectedData.email = $('#modal-price-demo-container-detail-work-email').length ? $('#modal-price-demo-container-detail-work-email').val() : null
    window.htr_showModalPriceDemo.selectedData.product_id = window.htr_showModalPriceDemo.productData.id ? window.htr_showModalPriceDemo.productData.id : null

    proceedToSaveLeadInfo()

  }

  function proceedToSaveLeadInfo () {
    window.storeVisitorFields(window.htr_showModalPriceDemo.selectedData)
    let leadRequestCreateUrl
    if (!window.htr_showModalPriceDemo.leads) {
      leadRequestCreateUrl = `/api/lead-requests`
    } else {
      leadRequestCreateUrl = `/api/lead-requests/${ window.htr_showModalPriceDemo.leads.id }`
    }

    let $dataToSend = window.htr_showModalPriceDemo.selectedData

    if (Array.isArray(window.htr_showModalPriceDemo.selectedTopProducts) && window.htr_showModalPriceDemo.selectedTopProducts.length > 0) {
      $dataToSend.vendors = window.htr_showModalPriceDemo.selectedTopProducts
    }

    $.when(
      $.ajax({
        method: window.htr_showModalPriceDemo.leads ? 'PUT' : 'POST',
        url: leadRequestCreateUrl,
        data: $dataToSend
      }),
    ).then(function (lead) {
      let spinnerElement = $('#modal-price-demo-spinner-02')
      let contentElement = $('#modal-price-demo-container-info-content-00')
      let footerElement = $('#modal-price-demo-container-info-footer-00')
      contentElement.hide()
      footerElement.hide()
      spinnerElement.show()
      window.htr_showModalPriceDemo.leads = lead
      storeRecolectedInfo(lead)
    }, function () {
      alert('Error')
    })
  }


  async function storeRecolectedInfo (lead) {
    let firstProductId
    let localAdditionalProductsIds
    let localParams = Object.assign({}, window.htr_showModalPriceDemo.selectedData)

    try {
      // we catch exceptions in case no elements in array
      if(window.htr_showModalPriceDemo.selectedTopProducts) {
        localAdditionalProductsIds = [...window.htr_showModalPriceDemo.selectedTopProducts]
        firstProductId = localAdditionalProductsIds.shift()
      }
    } catch (error) {}

    if (firstProductId !== undefined ) {
      let updateResponse = await mainProjectLead(lead, firstProductId)
      // we must save the product id as the main lead

      localParams.product_id = updateResponse.product_id
      if (updateResponse.product_id !== firstProductId) {
        localAdditionalProductsIds.push(firstProductId)
      }
    }

    let updateUserUrl = `/api/leads/${ lead.id }/users`

    $.when(
      $.ajax({
        method: 'POST',
        url: updateUserUrl,
        data: localParams
      }),
    ).then(function (data) {

      if (Number(data) === -1) {
        clearingWindowDom(true)
        window.htr_closeModal(modalContainerId)
        return false
      }

      $.ajax({
        method: 'POST',
        url: `/api/leads/${ lead.id }/additional-project`,
        data: {
          additional_products: localAdditionalProductsIds
        }
      }).done((data) => {

      })
      let buyerGuide = null
      let dataForm = {

        download_type: 'free',
        need_soon: true,
        send_me_tips: false

      }
      dataForm = Object.assign(dataForm, window.htr_showModalPriceDemo.selectedData)

      if(window.htr_showModalPriceDemo.hasOwnProperty('productData') && window.htr_showModalPriceDemo.productData != null
        && window.htr_showModalPriceDemo.productData.hasOwnProperty('category') && window.htr_showModalPriceDemo.productData.category != null
        && window.htr_showModalPriceDemo.productData.category.hasOwnProperty('buyers_guide_id') && window.htr_showModalPriceDemo.productData.category.buyers_guide_id != null) {
          buyerGuide = window.htr_showModalPriceDemo.productData.category.buyers_guide_id
        }

      if (buyerGuide) {
        $.ajax({
          method: 'POST',
          url: `/api/buyers-guides/${buyerGuide}/downloads`,
          data: dataForm
        }).done((data) => {
        })
      }

      sendGA()
      if (data.redirect_to !== undefined) {
        window.location.href = data.redirect_to
        clearingWindowDom(true)
        return false
      } else {
        printResendVerificationLink()
        window.htr_showModalPriceDemo.user_id = data.id
        return true
      }
    })
  }

  async function mainProjectLead (lead, productId) {
    let result
    let mainProjectLeadUrl = `/api/leads/${ lead.id }/main-project-lead`

    try {
      result = await $.ajax({
        url: mainProjectLeadUrl,
        type: 'POST',
        data: {
          'productId': productId
        }
      })

      return result
    } catch (error) {
      console.error(error)
    }
  }

  function sendGA () {
    switch (window.htr_showModalPriceDemo.requestType) {
      case 'request_demo':
        ga('send', 'event', 'lead request', 'submit', 'demo')
        break;

      case 'request_quote':
        ga('send', 'event', 'lead request', 'submit', 'price')
        break;

      case 'find_reference':
        ga('send', 'event', 'lead request', 'submit', 'reference')
        break;

      case 'ask_a_question':
        ga('send', 'event', 'lead request', 'submit', 'question')
        break;
    }
  }

  function resendEmailToUser () {

    let inputEmailElement = $('#modal-price-demo-container-info-content-resend-link-email-00')
    if(validateBusinessEmail(inputEmailElement.val())){
      inputEmailElement.parent().removeClass('modal-price-demo-container-info-content-resend-link-email-input-button-holder-error')
      inputEmailElement.parent().addClass('modal-price-demo-container-info-content-resend-link-email-input-button-holder-success')
      let lead = window.htr_showModalPriceDemo.leads.id ? window.htr_showModalPriceDemo.leads.id : null
      if (lead === null || !window.htr_showModalPriceDemo.selectedData || !window.htr_showModalPriceDemo.user_id) {
        return 0
      }

      window.htr_showModalPriceDemo.selectedData.email = document.getElementById('modal-price-demo-container-info-content-resend-link-email-00').value

      $.when(
        $.ajax({
          method: 'PUT',
          url: `/api/leads/${ lead }/users/${ window.htr_showModalPriceDemo.user_id }`,
          data: window.htr_showModalPriceDemo.selectedData
        }),
      ).then(function (data) {
        userRegisterResendConfirmation('modal-price-demo-aux-modal-container-01')
      }, function (e) {
        alert(e.responseJSON.message)
      })
    } else {
      inputEmailElement.parent().removeClass('modal-price-demo-container-info-content-resend-link-email-input-button-holder-success')
      inputEmailElement.parent().addClass('modal-price-demo-container-info-content-resend-link-email-input-button-holder-error')
    }

  }

  function userRegisterResendConfirmation (domIdForModal) {
    let modalContainerElement = $('#'+domIdForModal).length ? $('#'+domIdForModal) : null
    let htmlContent

    if(modalContainerElement === null) {
      return 0
    }


    htmlContent = `
    <div class="swal2-container swal2-center swal2-fade swal2-shown" style="overflow-y: auto;">
      <div role="dialog" aria-modal="true" aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-modal swal2-show" tabindex="-1" style="width: 500px; padding: 20px; background: rgb(255, 255, 255); display: block;">
        <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: block;">
          <div class="swal2-success-circular-line-left" style="background: rgb(255, 255, 255);"></div>
          <span class="swal2-success-line-tip swal2-animate-success-line-tip"></span>
          <span class="swal2-success-line-long swal2-animate-success-line-long"></span>
          <div class="swal2-success-ring"></div>
          <div class="swal2-success-fix" style="background: rgb(255, 255, 255);"></div>
          <div class="swal2-success-circular-line-right" style="background: rgb(255, 255, 255);"></div>
        </div>
        <h2 class="swal2-title" id="swal2-title">${ htrLanguage.company.new_email_sent || 'new_email_sent' }</h2>
        <div class="swal2-buttonswrapper" style="display: block;">
          <button
            type="button"
            class="swal2-confirm swal2-styled"
            aria-label=""
            style="background-color: rgb(48, 133, 214); border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">
            ${ htrLanguage.company.ok || 'ok' }
          </button>
        </div>
      </div>
    </div>`

    modalContainerElement.append(htmlContent)
    $('.swal2-container').on('click', function (e) {
      if (e.target !== this) {

        return
      }
      modalContainerElement.empty()
    })

    $('.swal2-buttonswrapper > button').on('click', function (event) {
      modalContainerElement.empty()
    })

  }


  function exitWarningModal (domIdForModal) {
    let modalContainerElement = $('#'+domIdForModal).length ? $('#'+domIdForModal) : null
    let htmlContent

    if(modalContainerElement === null) {
      return 0
    }


    htmlContent = `
    <div id="modal-price-demo-exit-warning-wrapper" class="wrap-popup-box modal-close-lead">
      <div class="popup-box">
        <button id="modal-price-demo-exit-warning-cross" class="btn-close">
          <i class="icon-cross"></i>
        </button>
        <h4 class="popup-box__title">
          ${ htrLanguage.company.exit_warning_1 || 'exit_warning_1' } ${ translate(window.htr_showModalPriceDemo.productData.category, 'name') } ${ htrLanguage.company.exit_warning_2 || 'exit_warning_2' }
        </h4>
        <a id="modal-price-demo-exit-warning-quiz" class="btn-quiz cursorPointer">${ (htrLanguage.home.take_the_quiz || 'take_the_quiz').charAt(0).toUpperCase() + (htrLanguage.home.take_the_quiz || 'take_the_quiz').toLowerCase().slice(1) }</a>
        <button id="modal-price-demo-exit-warning-no" class="btn-cancel modal-price-demo-wrapper-background-white">
          ${ htrLanguage.company.no_thanks || 'no_thanks' }
        </button>
      </div>
    </div>`

    modalContainerElement.append(htmlContent)
    $('#modal-price-demo-exit-warning-wrapper').on('click', function (e) {
      if (e.target !== this) {

        return
      }
      modalContainerElement.empty()
    })

    $('#modal-price-demo-exit-warning-quiz').on('click', (event) => {
      clearingWindowDom(true)
      window.location.href = '/get-advice'
    })

    $('#modal-price-demo-exit-warning-no').on('click', (event) => {
      clearingWindowDom(true)
      window.htr_closeModal(modalContainerId)
    })

    $('#modal-price-demo-exit-warning-cross').on('click', (event) => {
      modalContainerElement.empty()
    })

  }

  function notForVendor(domIdForModal) {

    let modalContainerElement = $('#'+domIdForModal).length ? $('#'+domIdForModal) : null
    let htmlContent

    if(modalContainerElement === null) {
      return 0
    }

    htmlContent = `

      <div class="popup-project popup-vendor-user">
        <div id="modal-price-demo-no-quiz-wapper" class="wrap-popup-box">
          <div class="popup-box">
            <button id="modal-price-demo-no-quiz-popup-close" class="btn-close">
              <i class="icon-cross"></i>
            </button>
            <div class="popup-box-welcome">
              <div class="popup-box__col-left">
                <h3 class="popup-box__col-left__title">
                  ${ htrLanguage.company.not_for_vendor_title || 'not_for_vendor_title' }
                </h3>
                <p>${ htrLanguage.company.not_for_vendor_desc || 'not_for_vendor_desc' }  </p>
                <button id="modal-price-demo-no-quiz-button" class="button button-close-popup-vendor">
                  ${ htrLanguage.company.close_window || 'close_window' }
                </button>
              </div>
              <div class="popup-box__col-right">
                <img src="/images/welcom-chat.svg">
              </div>
            </div>
          </div>
        </div>
      </div>`

    modalContainerElement.append(htmlContent)
    $('#modal-price-demo-no-quiz-wapper').on('click', function (e) {
      if (e.target !== this) {
        return
      }
      modalContainerElement.empty()
    })
    $('#modal-price-demo-no-quiz-popup-close, #modal-price-demo-no-quiz-button').on('click', function (e) {
      modalContainerElement.empty()
    })

  }

  function preparePhoneInputToOnlyAcceptFormat() {
    const isNumericInput = (event) => {
        const key = event.keyCode
        return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            )
    };

    const isModifierKey = (event) => {
        const key = event.keyCode
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
              // Allow Ctrl/Command + A,C,V,X,Z
              (event.ctrlKey === true || event.metaKey === true) &&
              (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    }

    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
          event.preventDefault()
      }
    }

    const formatToPhone = (event) => {
      if(isModifierKey(event)) {return}

      event.target.value = new AsYouType(($('#modal-price-demo-container-detail-phone-country-code') ? $('#modal-price-demo-container-detail-phone-country-code').attr('code') : '').toUpperCase()).input(event.target.value)

      callOnce(validateInnerElements, 500 )
    }

    const inputElement = document.getElementById('modal-price-demo-container-detail-phone-number')
    inputElement.addEventListener('keydown',enforceFormat)
    inputElement.addEventListener('keyup',formatToPhone)
  }

  function resizeHeigthOnMobile() {
    if(window.matchMedia('(max-width: 767px)').matches) {
      let innerHeight = window.innerHeight
      let headerHeight = $('#modal-price-demo-container-info-header-00').height()
      let footerHeight = $('#modal-price-demo-container-info-footer-00').height()
      let calculatedHeight = innerHeight - 15 - headerHeight - footerHeight
      $('#modal-price-demo-container-info-content-00').height(calculatedHeight)
    }
  }

  /**
   * calls the function func once within the within time window.
   * this is a debounce function which actually calls the func as
   * opposed to returning a function that would call func.
   *
   * @param func    the function to call
   * @param within  the time window in milliseconds, defaults to 300
   * @param timerId an optional key, defaults to func
   */
  function callOnce(func, within=300, timerId=null){
    window.callOnceTimers = window.callOnceTimers || {}
    if (timerId == null)
      timerId = func
    var timer = window.callOnceTimers[timerId]
    clearTimeout(timer)
    timer = setTimeout(() => func(), within)
    window.callOnceTimers[timerId] = timer
  }

  function verifyEmailForLead(email) {
    const lead = window.htr_showModalPriceDemo.leads.id
    const wasDisabled = $("#modal-price-demo-container-detail-work-email").prop('disabled')
    $.ajax({
      url: `/api/lead-requests/brite-verify-email`,
      data: { email: email},
      method: 'POST',
      timeout: 20000,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `Bearer ${ window.Laravel.apiToken }`)
        $("#modal-price-demo-container-detail-work-email").prop('disabled', true)
      },
      success: (data) => {
        window.htr_showModalPriceDemo.leads.email = data
        callOnce(validateInnerElements, 500 )
      },
      complete: () => {
        $('#modal-price-demo-container-detail-work-email').prop('disabled', wasDisabled)
      }
    })
  }

  function verifyPhoneForLead(phone) {
    const lead = window.htr_showModalPriceDemo.leads.id
    $.ajax({
      url: `/api/lead-requests/brite-verify-phone`,
      data: { phone },
      method: 'POST',
      timeout: 20000,
      beforeSend: (xhr) => {
        $("#modal-price-demo-container-detail-phone-number").prop('disabled', true)
        xhr.setRequestHeader('Authorization', `Bearer ${ window.Laravel.apiToken }`)
      },
      success: (data) => {
        window.htr_showModalPriceDemo.leads.phone = data
        callOnce(validateInnerElements, 500 )
      },
      complete: () => {
        $('#modal-price-demo-container-detail-phone-number').prop('disabled', false)
      }
    })
  }

  function validateHttpFormat(url) {
    let completeURL = null
    if(url.includes('http://') || url.includes('https://')) {
      return url
    } else {
      completeURL = 'https://' + url
      return completeURL
    }
  }
}
window.htr_showModalPriceDemo_html = {
    emptyProductList:
    `<div class="flex flex-row gap-x-2 rounded bg-[#D8FBF1] ps-[15px] pe-[20px] py-[14px] relative start-[8px] w-[507px] mt-[13px]" role="alert">
      <i class="fas fa-check-circle text-base leading-[18px] text-[#2DCA9F] mt-[1px]"></i>
      <div class="flex flex-col">
        <p class="text-[#177E62] text-sm leading-[18px] font-medium mb-1">${ window.i18n?.home?.you_already_requested_quotes_from_your_top_matches || 'You already requested quotes from your top matches'}</p>
        <p class="text-[#208E70] text-sm leading-[18px] font-normal	">${ window.i18n?.home?.it_looks_like_requested || 'It looks like you’ve already requested quotes from your top matches to compare to.'}</p>
        <p class="text-[#208E70] text-sm leading-[18px] font-normal	mb-0">${ window.i18n?.home?.click_the_green_button_below || 'Click the green button below to finish processing your request.'}</p>
      </div>
    </div>`
  }

// END MODAL PRICE DEMO - SECTION
